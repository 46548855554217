<script setup>
import { storeToRefs } from "pinia/dist/pinia";

import { useShipApiStore } from "@/stores/shipApi";
import AddShipTableRow from "./AddShipTableRow.vue";

const store = useShipApiStore();
const { items } = storeToRefs(store);
</script>

<template>
  <v-card class="fill-height pt-1" flat>
    <v-lazy class="fill-height">
      <v-table
        ref="table"
        class="overflow-y-auto"
        style="height: 90vh"
        hover
        height="fill-height"
        fixed-header
      >
        <thead>
          <tr>
            <th class="text-left" colspan="5"></th>
            <th class="text-center border-left-grey" colspan="2">
              Draught [m]
            </th>
            <th class="text-center border-left-grey" colspan="3">
              Depth to keel [m]
            </th>
            <th class="text-center pr-0 border-left-grey" colspan="2">
              Parallel body length [m]
            </th>
          </tr>
          <tr>
            <th class="text-center"></th>
            <th class="text-center">Type</th>
            <th class="text-center">Capacity [{{ store.getCapacityUnit }}]</th>
            <th class="border-left-grey text-center">Length overall [m]</th>
            <th class="text-center">Breadth [m]</th>
            <th class="text-center border-left-grey">Laden</th>
            <th class="text-center">Ballasted</th>
            <th class="text-center border-left-grey">Main deck</th>
            <th class="text-center">Forecastle</th>
            <th class="text-center">Poop</th>
            <th class="text-center border-left-grey">Aftward</th>
            <th class="text-center">Forward</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="items.length" v-for="item in store.getScaledItems">
            <AddShipTableRow :item="item" />
          </template>
        <template v-else>
          <tr>
            <td class="text-center" colspan="11">No data available</td>
          </tr>
        </template>
        </tbody>
      </v-table>
    </v-lazy>
  </v-card>
</template>
