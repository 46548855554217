<script setup>
import Field from "../form/Field.vue";
import ChoiceField from "../form/ChoiceField.vue";
import { useCalculationStore } from "@/stores/calculation";
import { storeToRefs } from "pinia/dist/pinia";
import { watch } from "vue";
import { useShipStore } from "@/stores/ship";
import { useShipApiStore } from "@/stores/shipApi";
import { CONTINUOUS, ISLAND } from "@/components/calculation/berthTypes";
import CalculationFormIsland from "@/components/calculation/CalculationFormIsland.vue";
import CalculationFormContinuous from "@/components/calculation/CalculationFormContinuous.vue";

const { currentItem } = storeToRefs(useCalculationStore());
const { items } = storeToRefs(useShipStore());

const store = useCalculationStore();
const shipApiStore = useShipApiStore();

watch(
  () => currentItem.value.mooring_side,
  (newValue) => {
    if (newValue) {
      shipApiStore.resetState();
      shipApiStore.fetchItems();
    }
  }
);

watch(
  () => currentItem.value.ship_type,
  (newValue) => {
    if (newValue) {
      shipApiStore.resetState();
      shipApiStore.fetchItems();
    }
  }
);
</script>

<template>
  <v-list-item class="pb-0 pt-4">
    <v-row>
      <v-col>
        <ChoiceField
          v-if="store.fields['berth_type']"
          :store="store"
          :field="store.fields['berth_type']"
          :item="currentItem"
          hide-details
          @update="store.update()"
        ></ChoiceField>
      </v-col>
      <v-col>
        <ChoiceField
          v-if="store.fields['ship_type']"
          :disabled="items.length > 0"
          :store="store"
          :field="store.fields['ship_type']"
          :item="currentItem"
          hide-details
          @update="store.update()"
        ></ChoiceField>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <Field
          v-if="store.fields['actual_berthing_orientation']"
          :store="store"
          :field="store.fields['actual_berthing_orientation']"
          :item="currentItem"
          @update="store.update()"
        ></Field>
      </v-col>
      <v-col>
        <ChoiceField
          v-if="store.fields['mooring_side']"
          :disabled="items.length > 0"
          :store="store"
          :field="store.fields['mooring_side']"
          :item="currentItem"
          @update="store.update()"
        ></ChoiceField>
      </v-col>
    </v-row>
  </v-list-item>
  <v-divider></v-divider>
  <v-list-item class="pb-0 pt-4">
    <v-row>
      <v-col cols="6">
        <ChoiceField
          v-if="store.fields['high_low_water']"
          :store="store"
          :field="store.fields['high_low_water']"
          :item="currentItem"
          @update="store.update()"
        ></ChoiceField>
      </v-col>
      <v-col>
        <Field
          :store="store"
          :field="store.fields['water_level']"
          :step="0.1"
          :item="currentItem"
          @update="store.update()"
        ></Field>
      </v-col>
    </v-row>
  </v-list-item>
  <v-divider></v-divider>
  <CalculationFormIsland v-if="currentItem.berth_type === ISLAND" />
  <CalculationFormContinuous v-if="currentItem.berth_type === CONTINUOUS" />
</template>
