<script setup>
import ProjectTitle from "./ProjectTitle.vue";
import CalculationForm from "./CalculationForm.vue";
import CreateDialog from "../dialog/CreateDialog.vue";
import _ from "lodash";
import { computed, watch, onMounted, ref } from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import { useCalculationStore } from "@/stores/calculation";
import { useProjectStore } from "@/stores/project";
import { useRoute } from "vue-router/dist/vue-router";
import router from "../../router";
import { Speckle } from "@rhdhv/vue-speckle";
import { useAuthorizationStore } from "@/stores/authorization";

const route = useRoute();

const store = useCalculationStore();
const projectStore = useProjectStore();
const authorizationStore = useAuthorizationStore();

const { currentItem, items, loading } = storeToRefs(useCalculationStore());

let rail = ref(true);
let drawer = ref(true);
const exportDrawer = ref(false);

const currentItemLoaded = computed({
  get() {
    return !_.isEmpty(currentItem.value);
  },
});

function navigateToCalculationPage() {
  router.push({ name: "project-detail", params: { id: route.params.id } });
}

function setDrawer() {
  exportDrawer.value = false;
  drawer.value = !drawer.value;
}

function setExportDrawer() {
  drawer.value = false;
  exportDrawer.value = !exportDrawer.value;
}

onMounted(async () => {
  await projectStore.fetchItem(route.params.id);
  await store.fetchOptions();
  await store.fetchItems({ project: route.params.id });
  const item = items.value.find(
    (item) => item.id === parseInt(route.params.calculationId)
  );
  store.setCurrentItem(item);
});
</script>

<template>
  <v-navigation-drawer v-model="rail" rail>
    <v-list>
      <v-list-item
        prepend-icon="mdi-file-edit"
        @click.stop="setDrawer()"
      ></v-list-item>
      <v-list-item @click.stop="setExportDrawer()">
        <v-icon size="x-large" icon="custom:speckleLogoIcon"></v-icon>
      </v-list-item>
      <v-tooltip location="right" open-delay="500" max-width="300px">
        <template #activator="{ props }">
          <v-list-item
            v-if="store.downloadLoading"
            class="text-center pl-0 pr-0"
          >
            <v-progress-circular
              indeterminate
              size="23"
              width="3"
              color="grey"
            ></v-progress-circular>
          </v-list-item>
          <v-list-item
            v-else
            prepend-icon="mdi-microsoft-excel"
            v-bind="props"
            @click="async () => await store.export()"
          ></v-list-item>
        </template>
        <span>
          Click this button to download excel file. The file is exported from
          the calculation.</span
        >
      </v-tooltip>
    </v-list>
  </v-navigation-drawer>
  <v-navigation-drawer
    v-model="drawer"
    scrim="rgba(0,0,0,0)"
    temporary
    elevation="2"
    width="500"
  >
    <v-list class="pb-0">
      <v-list-item @click.stop="setDrawer()">
        <template #title>
          <ProjectTitle />
        </template>
        <template #append>
          <v-btn
            variant="text"
            :icon="!drawer ? 'mdi-chevron-right' : 'mdi-chevron-left'"
            @click.stop="setDrawer;"
          ></v-btn>
        </template>
      </v-list-item>

      <v-progress-linear
        v-if="loading || !currentItemLoaded"
        indeterminate
        color="primary"
      >
      </v-progress-linear>

      <template v-else>
        <v-divider></v-divider>
        <v-tooltip>
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-keyboard-return"
              @click="navigateToCalculationPage()"
            >
              <v-list-item-title class="tex-h6">
                {{ currentItem.name }}
              </v-list-item-title>
            </v-list-item>
          </template>
          Back to calculations
        </v-tooltip>
        <v-divider></v-divider>
        <v-list
          class="pa-0 ma-0"
          :disabled="
            !authorizationStore.hasServicePermission({
              authSource: 'ProjectUser',
              allowed: 'change_calculation',
            })
          "
        >
          <template v-if="store.fields && currentItemLoaded">
            <CalculationForm></CalculationForm>
          </template>
        </v-list>
      </template>
    </v-list>
  </v-navigation-drawer>

  <v-navigation-drawer
    v-model="exportDrawer"
    scrim="rgba(0,0,0,0)"
    temporary
    elevation="2"
    width="500"
  >
    <v-list class="pb-0">
      <v-list-item @click.stop="setExportDrawer()">
        <template #title>
          <v-card-title class="pl-0">
            <v-icon size="large" icon="custom:speckleLogoIcon"></v-icon>
            Speckle
          </v-card-title>
        </template>
        <template #append>
          <v-btn
            variant="text"
            :icon="!exportDrawer ? 'mdi-chevron-right' : 'mdi-chevron-left'"
            @click.stop="setExportDrawer()"
          ></v-btn>
        </template>
      </v-list-item>

      <v-list-item>
        <Speckle :id="currentItem.speckle" only-send />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <CreateDialog :store="store" :name="'calculation'"></CreateDialog>
</template>
