import { defineStore } from "pinia";
import AppBaseStore from "./appBaseStore";
import API from "@/api/config/apiUrls";

import { useApi } from "@/api/config/useApi";

const { api } = useApi();
const baseUrl = API.ROLES.INDEX;
const baseStore = new AppBaseStore(baseUrl, api);

export const useAuthorizationStore = defineStore({
  id: "authorization",
  state: () => ({
    ...baseStore.state,
  }),
  actions: {
    ...baseStore.actions,
    setCurrentItem(roleIds) {
      roleIds.forEach((roleId) => {
        const role = this.getCurrentRole(roleId);
        this.currentItem[role.auth_source] = role.permissions;
      });
    },
    getCurrentRole(roleId) {
      return this.items.find((item) => item.id === roleId);
    },
    hasServicePermission({ authSource, allowed }) {
      if (typeof allowed === "string") {
        allowed = [allowed];
      }
      return this.currentItem[authSource]?.some((permission) =>
        allowed.includes(permission)
      );
    },
    hasItemPermission({ authInstance, allowed }) {
      return authInstance.role.permissions.some((permission) =>
        allowed.includes(permission)
      );
    },
    resetState() {
      this.items = [];
      this.currentItem = {};
    },
  },
  getters: {
    ...baseStore.getters,
  },
});
