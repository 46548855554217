<script setup>
import ProjectCardMenu from "./ProjectCardMenu.vue";
import { computed, onMounted, ref } from "vue";
import { useProjectStore } from "../../stores/project";
import { storeToRefs } from "pinia/dist/pinia";
import router from "../../router";

const props = defineProps({ item: Object });

const { currentItem } = storeToRefs(useProjectStore());

const isHovering = ref(false);
const store = useProjectStore();

function navigateToProject() {
  router.push({ name: "project-detail", params: { id: props.item.id } });
}
</script>

<template>
  <v-col
    cols="12"
    xs="12"
    md="3"
    class="col-sm-12 col-xs-12 col-md-6 col-lg-3 pa-3"
  >
    <v-card
      hover
      :elevation="isHovering ? 4 : 2"
      v-bind="props"
      @click.stop="navigateToProject()"
      @mouseover="isHovering = true"
      @mouseout="isHovering = false"
    >
      <v-row class="pt-2 pr-3">
        <v-col cols="10" class="pr-0 pb-0">
          <v-card-title class="elip">
            <span style="font-size: 1.1rem; font-weight: 400"
              >{{ item.number }}
            </span>
            <span style="opacity: 0.25"> |</span> {{ item.name }}
          </v-card-title>
        </v-col>
        <v-col cols="2" class="pt-4">
          <ProjectCardMenu :item="item" />
        </v-col>
      </v-row>

      <div class="pl-4">
        <v-chip class="mr-3" prepend-icon="mdi-map-marker" size="x-small" label>
          {{ item.location }}
        </v-chip>
        <span
          style="
            color: rgba(0, 0, 0, 0.7);
            font-size: 14px;
            font-family: Roboto, sans-serif;
          "
        >
          {{ item.client }}
        </span>
      </div>
      <v-tooltip
        v-if="item.description"
        location="bottom"
        style="overflow-wrap: break-word"
        max-width="400px"
      >
        <template #activator="{ props }">
          <v-card-text class="elip pt-4 pb-2" v-bind="props">
            {{ item.description }}
          </v-card-text>
        </template>
        <span ref="element">{{ item.description }}</span>
      </v-tooltip>
      <v-card-text v-else class="pb-2">-</v-card-text>
    </v-card>
  </v-col>
</template>
