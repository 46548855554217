<template>
  <router-link to="/">
    <v-img
      alt="Logo"
      class="shrink mr-2"
      :src="'/rhdhv-logo.jpg'"
      contain
      transition="scale-transition"
      height="80"
      width="200"
    >
    </v-img>
  </router-link>
</template>
<style scoped>
:deep(.v-toolbar__extension) {
  padding: 0 !important;
}
</style>
