<script setup>
import { storeToRefs } from "pinia/dist/pinia";
import { useCalculationStore } from "@/stores/calculation";
import { useProjectStore } from "@/stores/project";
import RHDHVButton from "@/components/common/RHDHVButton.vue";

const { currentItem: currentProject } = storeToRefs(useProjectStore());

const store = useCalculationStore();
</script>

<template>
  <v-card elevation="0">
    <v-card-title>
      Calculations

      <RHDHVButton
        allowed="add_calculation"
        auth-source="ProjectUser"
        @click="store.toggleCreateUpdateDialog({ project: currentProject.id })"
      >
        Add
      </RHDHVButton>
    </v-card-title>
    <v-card-subtitle>
      Manage calculations in this project, please select an existing calculation
      below or create a new calculation via the
      <v-chip
        class="ml-1 mr-1"
        append-icon="mdi-plus"
        label
        variant="outlined"
        size="x-small"
        color="primary"
      >
        ADD
      </v-chip>
      button.
    </v-card-subtitle>
  </v-card>
</template>
