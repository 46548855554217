<script setup>
import useEventsBus from "../../bus/eventBus";
import { watch, ref } from "vue";
import _ from "lodash";

const { bus } = useEventsBus();

let loading = ref(false);

watch(
  () => bus.value.get("loading"),
  (val) => {
    loading.value = val[0];
  }
);
</script>

<template>
  <v-progress-circular
    v-if="loading"
    color="rgba(0,0,0,0.12)"
    indeterminate
    :size="25"
    :width="3"
  ></v-progress-circular>
  <v-card-subtitle v-if="loading">Loading</v-card-subtitle>
  <v-card-subtitle v-else>All changes saved</v-card-subtitle>
</template>

<style scoped>
:deep(.v-toolbar__extension) {
  padding: 0 !important;
}

.v-toolbar__extension {
  padding: 0 !important;
}
</style>
