<script setup>
import ProjectIndexCard from "./ProjectCard.vue";
import { storeToRefs } from "pinia/dist/pinia";
import { useProjectStore } from "@/stores/project";

const { loading, items } = storeToRefs(useProjectStore());
</script>

<template>
  <div v-if="!loading" ref="input" class="pa-4 mt-4">
    <v-row v-if="items?.length">
      <ProjectIndexCard v-for="item in items" :item="item"></ProjectIndexCard>
    </v-row>
    <v-alert v-else type="warning" density="comfortable" variant="tonal">
      There are no projects available available. Please create a project to
      proceed.
    </v-alert>
  </div>
</template>
<script></script>
