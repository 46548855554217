<script setup>
import { storeToRefs } from "pinia";
import { useCalculationStore } from "@/stores/calculation";
import CalculationTableRow from "./CalculationTableRow.vue";
import ActionMenu from "../common/ActionMenu.vue";
import { useProjectStore } from "@/stores/project";
import TableLoadingBar from "@/components/common/TableLoadingBar.vue";
import NoDataBar from "@/components/calculationIndex/NoDataBar.vue";

const { currentItem, items, loading, menu, menuCoordinates } = storeToRefs(
  useCalculationStore()
);
const { currentItem: currentProject } = storeToRefs(useProjectStore());
const store = useCalculationStore();
</script>
<template>
  <v-table
    class="mt-4 d-flex flex-column overflow-y-auto"
    hover
    density="comfortable"
    fixed-header
    height="fill-height"
  >
    <thead>
      <tr>
        <th class="text-left">#</th>
        <th class="text-left">Name</th>
        <th class="text-left">Berth type</th>
        <th class="text-left">Ship type</th>
        <th class="text-left">Vessel mooring side</th>
        <th class="text-left">Description</th>
      </tr>
    </thead>

    <tbody>
      <TableLoadingBar v-if="loading" :show-text="!items.length" />
      <NoDataBar
        v-else-if="!items.length && !loading"
        text="No data, please add a calculation to continue."
      />
      <template v-for="(item, index) in items">
        <CalculationTableRow :order="index + 1" :item="item" />
      </template>
    </tbody>
  </v-table>

  <ActionMenu
    auth-source="ProjectUser"
    auth-model="calculation"
    :model-value="menu"
    :position-x="menuCoordinates.x"
    :position-y="menuCoordinates.y"
    delete-item
    edit
    duplicate
    @toggle="store.toggleMenu(undefined)"
    @delete="store.toggleDeleteDialog(currentItem)"
    @edit="store.toggleCreateUpdateDialog(currentItem)"
    @duplicate="
      store.toggleCreateUpdateDialog({
        project: currentProject.id,
        duplicated_id: currentItem.id,
      })
    "
  >
  </ActionMenu>
</template>
