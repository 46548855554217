<script setup>
import AppHeader from "./components/app/AppHeader.vue";
import { storeToRefs } from "pinia/dist/pinia";

import ErrorSnackbar from "./components/errorSnackbar/ErrorSnackbar.vue";
import { inject } from "vue";

const authStore = inject("useAuthStore");

const { user } = storeToRefs(authStore);
</script>

<template>
  <v-app>
    <AppHeader v-if="user"></AppHeader>
    <v-main>
      <router-view />
    </v-main>
    <ErrorSnackbar></ErrorSnackbar>
  </v-app>
</template>

<script></script>
<style lang="scss">
// the main file that imports everything related with styles
@import "/src/scss/settings.scss";
</style>
