<script setup>
import MooringPointsTable from "../mooringPoints/MooringPointsTable.vue";
import HelpTextTooltip from "../form/HelpTextTooltip.vue";
import ShipTable from "../ship/ShipTable.vue";
import AddShipDialog from "../addship/AddShipDialog.vue";

import { storeToRefs } from "pinia/dist/pinia";
import { computed, ref, watch } from "vue";

import { useOptimisationStore } from "@/stores/optimisation";
import { useCalculationStore } from "@/stores/calculation";
import { useMooringPointStore } from "@/stores/mooring_point";
import { useShipStore } from "@/stores/ship";
import { useFairleadStore } from "@/stores/fairlead";
import { useWinchStore } from "@/stores/winch";
import { useShipContourStore } from "@/stores/ship_contour";
import { useShipApiStore } from "@/stores/shipApi";
import { ISLAND } from "@/components/calculation/berthTypes";
import RHDHVButton from "@/components/common/RHDHVButton.vue";

const { currentItem: currentCalculation } = storeToRefs(useCalculationStore());
const {
  currentItem,
  loading: optimiseLoading,
  generateLoading,
} = storeToRefs(useOptimisationStore());
const { loading: mooringPointLoading } = storeToRefs(useMooringPointStore());
const { loading: shipLoading } = storeToRefs(useShipStore());

const optimisationStore = useOptimisationStore();
const mooringPointStore = useMooringPointStore();
const shipStore = useShipStore();
const fairleadStore = useFairleadStore();
const winchStore = useWinchStore();
const shipContourStore = useShipContourStore();
const shipApiStore = useShipApiStore();

let tab = ref("vessel");
const table = ref(null);

let dialog = ref(false);
let mooringPointHelpText = ref(
  "Here the coordinates (X, Y, Z) of all mooring points are shown; X and Y are relative to the ‘earth-fixed coordinate system (shown on the left) and Z is relative to CD"
);
let designVesselHelpText = ref(
  "Here you can define the design vessels, from certain vessel classes/ranges with ‘typical’ vessel particulars, which can be overwritten manually "
);

const mooringPointTableHeight = computed(() => {
  if (currentCalculation.value.berth_type === ISLAND) {
    return 88;
  } else {
    return 95;
  }
});

watch(currentCalculation, async (currentState) => {
  if (currentState.id) {
    await mooringPointStore.fetchOptions();
    await fairleadStore.fetchOptions();
    await shipStore.fetchOptions();
    await shipApiStore.fetchOptions();
    await optimisationStore.fetchItem(currentState.optimisation);
    await shipStore.fetchItems({ calculation: currentState.id });
    await fairleadStore.fetchItems({ ship__calculation: currentState.id });
    await mooringPointStore.fetchItems({ calculation: currentState.id });
    await winchStore.fetchItems({ ship__calculation: currentState.id });
    await shipContourStore.fetchItems({ ship__calculation: currentState.id });
  }
});
</script>

<template>
  <div class="d-flex fill-height flex-column">
    <v-card
      ref="table"
      class="pa-0"
      style="border-radius: 0"
      height="90%"
      width="100%"
      elevation="2"
    >
      <v-tabs v-model="tab" style="z-index: 1" fixed-tabs color="primary">
        <v-tab value="vessel">
          <span class="mr-3"> Design vessels </span>

          <v-progress-circular
            v-if="shipLoading"
            size="x-small"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <HelpTextTooltip
            v-else
            :text="designVesselHelpText"
            color="primary"
          ></HelpTextTooltip>
        </v-tab>
        <v-tab value="mooring">
          <span class="mr-3"> Mooring points </span>
          <v-progress-circular
            v-if="mooringPointLoading"
            size="x-small"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <HelpTextTooltip
            v-else
            :text="mooringPointHelpText"
            color="primary"
          ></HelpTextTooltip>
        </v-tab>
      </v-tabs>
      <v-window v-model="tab" class="d-flex flex-column fill-height">
        <v-window-item value="vessel">
          <div class="ma-0 d-flex flex-column fill-height">
            <div style="height: 7%">
              <div class="pl-3 pt-3 fill-height">
                <v-tooltip location="bottom" open-delay="500" max-width="500px">
                  <template #activator="{ props }">
                    <RHDHVButton
                      item-class="ml-0"
                      v-bind="props"
                      allowed="add_ship"
                      auth-source="ProjectUser"
                      variant="elevated"
                      append-icon="mdi-information"
                      :size="undefined"
                      @click="dialog = true"
                    >
                      Add
                    </RHDHVButton>
                  </template>
                  <span
                    >Click this button to add ships. Click right and select
                    'Delete' to delete a ship</span
                  >
                </v-tooltip>
              </div>
              <div
                v-if="!shipStore.itemsAvailable && !shipLoading"
                class="pa-3"
              >
                <v-alert type="info" density="compact">
                  There are no design vessels available, please click
                  <v-chip
                    class="ml-1 mr-1"
                    label
                    size="small"
                    variant="outlined"
                    >ADD
                  </v-chip>
                  to start selecting vessels.
                </v-alert>
              </div>
            </div>
            <div style="height: 88%" class="d-flex flex-column">
              <ShipTable></ShipTable>
            </div>
          </div>
        </v-window-item>

        <v-window-item value="mooring">
          <div class="pa-0 ma-0 d-flex flex-column fill-height">
            <div
              v-if="currentCalculation.berth_type === ISLAND"
              style="height: 7%"
            >
              <div class="pl-3 pt-3 fill-height">
                <v-tooltip location="bottom" open-delay="500" max-width="500px">
                  <template #activator="{ props }">
                    <RHDHVButton
                      item-class="ml-0"
                      v-bind="props"
                      allowed="add_mooringpoint"
                      auth-source="ProjectUser"
                      variant="elevated"
                      :size="undefined"
                      append-icon="mdi-information"
                      @click="
                        mooringPointStore.create({
                          calculation: currentCalculation.id,
                        })
                      "
                    >
                      Add
                    </RHDHVButton>
                  </template>
                  <span
                    >Click this button to create and allocate mooring points
                    manually; click right and select ‘Delete’ to delete a
                    mooring point</span
                  >
                </v-tooltip>
              </div>

              <!--              TODO take this out of this if else-->
            </div>
            <div
              v-if="!mooringPointStore.itemsAvailable && !mooringPointLoading"
              class="pa-3"
            >
              <v-alert
                v-if="currentCalculation.berth_type === ISLAND"
                type="info"
                density="compact"
              >
                There are no mooring points available, please select
                <v-chip
                  class="ml-1 mr-1"
                  label
                  variant="outlined"
                  append-icon="mdi-refresh"
                  size="small"
                  color="white"
                >
                  OPTIMISE
                </v-chip>
                to generate the mooring points.
              </v-alert>
              <v-alert v-else type="info" density="compact">
                There are no mooring points available, please select
                <v-chip
                  class="ml-1 mr-1"
                  label
                  variant="outlined"
                  append-icon="mdi-plus-box-multiple"
                  size="small"
                  color="white"
                >
                  GENERATE
                </v-chip>
                in the input tab, to generate the mooring points.
              </v-alert>
            </div>
            <div
              :style="`height: ${mooringPointTableHeight}%`"
              class="d-flex flex-column"
            >
              <MooringPointsTable></MooringPointsTable>
            </div>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
    <v-card
      class="center pa-0 ma-0 d-flex flex-column flex-grow-1"
      style="border-radius: 0"
    >
      <RHDHVButton
        v-if="currentCalculation.berth_type === ISLAND"
        item-class="ml-0"
        :loading="optimiseLoading"
        width="50%"
        allowed="add_mooringpoint"
        auth-source="ProjectUser"
        variant="elevated"
        size="large"
        append-icon="mdi-refresh"
        @click="optimisationStore.optimise()"
      >
        Optimise
      </RHDHVButton>

      <RHDHVButton
        v-else
        :disabled="generateLoading"
        :loading="optimiseLoading"
        item-class="ml-0"
        width="50%"
        allowed="change_mooringpoint"
        auth-source="ProjectUser"
        variant="elevated"
        size="large"
        append-icon="mdi-refresh"
        @click="optimisationStore.linkToFairleads()"
      >
        Link to fairleads
      </RHDHVButton>
    </v-card>
  </div>

  <AddShipDialog
    :dialog="dialog"
    @setDialog="
      (value) => {
        dialog = value;
      }
    "
  ></AddShipDialog>
</template>
<style scoped>
.v-window-item {
  height: calc(100vh - 90px - 48px - 84px); /* All toolbars  */
  overflow-y: auto;
}
</style>
