import { defineStore } from "pinia";
import AppBaseStore from "./appBaseStore";
import { useCalculationStore } from "./calculation";
import { useFairleadStore } from "./fairlead";
import { useOptimisationStore } from "./optimisation";
import { useShipStore } from "./ship";
import { useWinchStore } from "./winch";
import { useMooringPointStore } from "./mooring_point";
import API from "@/api/config/apiUrls";
import { useApi } from "@/api/config/useApi";

const { api } = useApi();

const baseUrl = API.CORE.PROJECTS.INDEX;
const baseStore = new AppBaseStore(baseUrl, api);

export const useProjectStore = defineStore({
  id: "projects",
  state: () => ({
    ...baseStore.state,
    filterFields: ["number", "name", "location", "client", "description"],
  }),
  actions: {
    ...baseStore.actions,
    resetAll() {
      const fairleadStore = useFairleadStore();
      const optimisationStore = useOptimisationStore();
      const winchStore = useWinchStore();
      const shipStore = useShipStore();
      const calculationStore = useCalculationStore();
      const mooringPointStore = useMooringPointStore();
      fairleadStore.$reset();
      winchStore.$reset();
      shipStore.$reset();
      optimisationStore.$reset();
      calculationStore.$reset();
      mooringPointStore.$reset();
      this.$reset();
    },
  },
  getters: {
    ...baseStore.getters,
    getCurrentProject(state) {
      return state.currentItem ? state.currentItem : null;
    },
  },
});
