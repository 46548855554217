<script setup>
import { useShipStore } from "../../stores/ship";
import { computed } from "vue";
import { useAuthorizationStore } from "@/stores/authorization";

const authorizationStore = useAuthorizationStore();
const canEdit = computed(() =>
  authorizationStore.hasServicePermission({
    authSource: "ProjectUser",
    allowed: "change_calculation",
  })
);
const store = useShipStore();

const props = defineProps({
  item: { type: Object, required: true },
});

const percentage = 100;
const value = computed({
  get() {
    return props.item.opacity * percentage;
  },
  set(value) {
    value = value / percentage;
    store.setParameter(props.item, "opacity", value);
  },
});
</script>
<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        icon="mdi-eye-settings"
        class="float-right"
        size="small"
        flat
        v-bind="props"
        @mousedown.stop
      >
      </v-btn>
    </template>

    <v-list density="compact">
      <v-list-item width="200" @click="store.setHide(props.item)">
        <v-list-item-title>
          <v-row>
            <v-col cols="3">
              <v-icon>
                {{ item.hide ? "mdi-eye" : "mdi-eye-off" }}
              </v-icon>
            </v-col>
            <v-col>
              {{ item.hide ? "Show" : "Hide" }}
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canEdit" width="200" @click.stop>
        <v-list-item-title>
          <v-row class="pt-2 pb-2">
            <v-col cols="3">
              <v-icon> mdi-opacity </v-icon>
            </v-col>
            <v-col class="pl-0 mr-3">
              <v-slider
                v-model.lazy="value"
                hide-details
                min="20"
                max="100"
                tick-size="4"
                show-ticks
                :ticks="{
                  20: '20%',
                  100: '100%',
                }"
                style="z-index: 1"
                @mouseup="store.update(item)"
              >
              </v-slider>
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>
      <!--      <v-list-item-->
      <!--          @click="store.delete(item)"-->
      <!--      >-->
      <!--        <v-list-item-title>-->
      <!--          <v-row>-->
      <!--            <v-col cols="3">-->
      <!--              <v-icon>-->
      <!--                mdi-delete-->
      <!--              </v-icon>-->
      <!--            </v-col>-->
      <!--            <v-col>-->
      <!--              Delete-->
      <!--            </v-col>-->
      <!--          </v-row>-->
      <!--        </v-list-item-title>-->
      <!--      </v-list-item>-->
    </v-list>
  </v-menu>
</template>
