export function calculateLineLength(mooringPoint, winch, fairlead) {
  if (!mooringPoint || !winch) {
    return null;
  }
  const distanceFairleadMooringPoint = Math.sqrt(
    Math.pow(mooringPoint.x - fairlead.x, 2) +
      Math.pow(mooringPoint.y - fairlead.y, 2) +
      Math.pow(mooringPoint.z - fairlead.z, 2)
  );
  return distanceFairleadMooringPoint;
}
