<script setup>
import { ref } from "vue";

import HelpTextTooltip from "../form/HelpTextTooltip.vue";
import { storeToRefs } from "pinia/dist/pinia";
import { useCalculationStore } from "@/stores/calculation";
import { useMooringPointStore } from "@/stores/mooring_point";
import { useFairleadStore } from "@/stores/fairlead";

const { currentItem: currentCalculation } = storeToRefs(useCalculationStore());
const mooringPointStore = useMooringPointStore();
const fairleadStore = useFairleadStore();
const { labels: mooringPointLabels } = storeToRefs(mooringPointStore);
const { labels: fairleadLabels, showWinch } = storeToRefs(fairleadStore);

const northTriangleColor = "red accent-4";
const snackbar = ref(null);
const timeout = ref(2000);

const emit = defineEmits(["resetZoom"]);

function copyImageToClipboard() {
  let image = document.getElementById("chart");
  image.toBlob((blob) =>
    navigator.clipboard.write([new ClipboardItem({ "image/png": blob })])
  );
  snackbar.value = true;
}
</script>

<template>
  <div style="position: absolute" class="ma-3">
    <v-menu width="300">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-cog" flat @mousedown.stop></v-btn>
      </template>

      <v-list density="compact">
        <v-list-item @click.stop="mooringPointStore.toggleLabels()">
          <v-list-item-title>
            <v-row>
              <v-col cols="3">
                <v-icon>
                  mdi-label
                  {{ mooringPointLabels ? "mdi-label-off" : "mdi-label" }}
                </v-icon>
              </v-col>
              <v-col class="pl-0">
                {{ mooringPointLabels ? "Hide" : "Show" }} mooring point label
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click.stop="fairleadStore.toggleLabels()">
          <v-list-item-title>
            <v-row>
              <v-col cols="3">
                <v-icon>
                  mdi-label
                  {{ fairleadLabels ? "mdi-label-off" : "mdi-label" }}
                </v-icon>
              </v-col>
              <v-col class="pl-0">
                {{ fairleadLabels ? "Hide" : "Show" }} line numbers
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click.stop="fairleadStore.toggleShowWinch()">
          <v-list-item-title>
            <v-row>
              <v-col cols="3">
                <v-icon>
                  mdi-label
                  {{ showWinch ? "mdi-label-off" : "mdi-label" }}
                </v-icon>
              </v-col>
              <v-col class="pl-0">
                {{ showWinch ? "Hide" : "Show" }} winch lines
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip location="right" open-delay="500">
      <template #activator="{ props }">
        <v-btn
          class="mr-0"
          v-bind="props"
          icon="mdi-arrow-expand-all"
          flat
          @click="emit('resetZoom')"
        >
        </v-btn>
      </template>
      <span>Reset graph to origin location and zoom location</span>
    </v-tooltip>
    <v-tooltip location="right" open-delay="500">
      <template #activator="{ props }">
        <v-btn
          class="mr-2"
          v-bind="props"
          icon="mdi-content-copy"
          flat
          @click="copyImageToClipboard()"
        >
        </v-btn>
      </template>
      <span>Copy the graph image to Clipboard</span>
    </v-tooltip>
    <HelpTextTooltip
      color="black"
      size="large"
      location="right"
      text="Click + drag to change the viewport, CTRL + scroll to zoom"
    ></HelpTextTooltip>

    <v-icon
      class="ml-4"
      :color="northTriangleColor"
      size="large"
      :style="`transform: rotate(${
        currentCalculation.actual_berthing_orientation * -1 + 90
      }deg)`"
    >
      mdi-arrow-up-bold-circle
    </v-icon>
  </div>
  <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
    Copied to clipboard!
    <template #actions>
      <v-btn icon="mdi-close" size="small" @click="snackbar = false"> </v-btn>
    </template>
  </v-snackbar>
</template>
