<script setup>
import { storeToRefs } from "pinia/dist/pinia";

import { useFairleadStore } from "../../stores/fairlead";
import { computed } from "vue";
import FairleadTableRow from "./FairleadTableRow.vue";

const store = useFairleadStore();

const { items } = storeToRefs(useFairleadStore());

const props = defineProps({
  ship: { type: Object, required: true },
});

const filterItems = computed({
  get() {
    return store.itemsCalculatedAngle.filter(
      (item) => item.ship === props.ship.id && item.winch
    );
  },
});
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <v-table
      v-if="store.itemsAvailable && store.optionsAvailable"
      density="compact"
      hover
    >
      <thead>
        <tr>
          <th colspan="5" class="border-bottom-grey border-right-grey"></th>
          <th
            colspan="4"
            class="text-center border-bottom-grey border-right-grey"
          >
            Fairlead properties
          </th>
          <th
            colspan="3"
            class="text-center border-bottom-grey border-right-grey"
          >
            Resulting line properties
          </th>
        </tr>
        <tr>
          <th>#</th>
          <th class="text-center pr-0">Fairlead</th>
          <th class="text-center pr-0">Winch</th>
          <th class="text-center pr-0">Dolphin</th>
          <th class="text-center">Line type</th>
          <th class="text-center pr-0 border-left-grey">X [m]</th>
          <th class="text-center pr-0">Y [m]</th>
          <th class="text-center border-right-grey">Z [m CD]</th>
          <th class="text-center border-right-grey pl-1 pr-1">Minimal (°)</th>
          <th class="text-center border-right-grey pl-1 pr-1">Length</th>
          <th class="text-center pr-0">Horizontal (°)</th>
          <th class="text-center">Vertical (°)</th>
        </tr>
      </thead>
      <tbody v-if="filterItems.length" class="text-center fill-height pb-3">
        <template v-for="(item, index) in filterItems">
          <FairleadTableRow :item="item" :index="index" />
        </template>
        <tr>
          <td colspan="48" style="height: 3px"></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="8" style="text-align: center">No data available</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<style scoped>
td {
  text-align: center;
}

th {
  text-align: center;
}
</style>
