<script setup>
import CalculationTable from "../components/calculationIndex/CalculationTable.vue";
import CalculationIndexTitle from "../components/calculationIndex/CalculationIndexTitle.vue";
import { onMounted } from "vue";
import { useCalculationStore } from "@/stores/calculation";
import { useRoute } from "vue-router";
import CreateOrUpdateDialog from "@/components/dialog/CreateOrUpdateDialog.vue";
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import { storeToRefs } from "pinia";

const route = useRoute();
const store = useCalculationStore();

const { deleteItem } = storeToRefs(store);

onMounted(async () => {
  await store.fetchOptions();
  await store.fetchItems({ project: route.params.id });
});

async function postOrUpdate() {
  const response = await store.allAction();
  if (response) {
    store.toggleCreateUpdateDialog();
  }
}
</script>
<template>
  <CalculationIndexTitle />
  <CalculationTable />
  <CreateOrUpdateDialog
    :store="store"
    :name="'calculation'"
    @save="postOrUpdate()"
  ></CreateOrUpdateDialog>
  <DeleteDialog :store="store" :name="deleteItem?.name"></DeleteDialog>
</template>
