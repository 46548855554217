import { defineStore, storeToRefs } from "pinia";
import { useProjectStore } from "./project";
import { useFairleadStore } from "@/stores/fairlead";
import { useOptimisationStore } from "@/stores/optimisation";
import { useWinchStore } from "@/stores/winch";
import { useShipStore } from "@/stores/ship";
import { useMooringPointStore } from "@/stores/mooring_point";
import AppBaseStore from "@/stores/appBaseStore";
import API from "@/api/config/apiUrls";
import { useApi } from "@/api/config/useApi";

const { api } = useApi();

const baseUrl = API.CORE.CALCULATIONS.INDEX;
const baseStore = new AppBaseStore(baseUrl, api);

export const useCalculationStore = defineStore({
  id: "calculation",
  state: () => ({
    ...baseStore.state,
    filterFields: ["name", "description"],
    downloadLoading: false,
  }),
  actions: {
    ...baseStore.actions,
    resetAll() {
      const fairleadStore = useFairleadStore();
      const optimisationStore = useOptimisationStore();
      const winchStore = useWinchStore();
      const shipStore = useShipStore();
      const mooringPointStore = useMooringPointStore();
      fairleadStore.$reset();
      winchStore.$reset();
      shipStore.$reset();
      optimisationStore.$reset();
      mooringPointStore.$reset();
      this.$reset();
    },
    async allAction(item = null) {
      if (this.currentItem.id) {
        return await this.update(item);
      } else {
        if (this.currentItem.duplicated_id) {
          return await this.duplicate(item);
        } else {
          return await this.create(item);
        }
      }
    },
    async duplicate(item = null) {
      this.writeLoading = true;
      const postItem = item ? item : this.currentItem;
      const duplicatedURL = baseUrl + "/duplicate";
      try {
        let response = await api.service(duplicatedURL).post(postItem);
        this.items.push(response);
        this.dialog = false;
        this.writeLoading = false;

        return response;
      } catch (e) {
        console.log(e);
      }
      this.writeLoading = false;
    },
    async export() {
      if (this.currentItem) {
        this.downloadLoading = true;
        const exportURL = baseUrl + "/export";
        const projectStore = useProjectStore();
        const { currentItem: currentProject } = storeToRefs(projectStore);

        const fileName =
          currentProject.value["number"] +
          "_" +
          currentProject.value["name"] +
          "_" +
          this.currentItem.name +
          ".xlsx";
        try {
          await api
            .service(exportURL)
            .getBlob(this.currentItem.id, { fileName: fileName });
        } catch (e) {
          console.log(e);
        }
        this.downloadLoading = false;
      }
    },
    setStreamId(streamId) {
      this.currentItem.stream_id = streamId;
    },
  },
  getters: {
    ...baseStore.getters,
    getCalculationId(state) {
      return state.currentItem ? state.currentItem.id : null;
    },
    getCurrentMooringSide: (state) => {
      return state.currentItem ? state.currentItem.mooring_side : "PORT_SIDE";
    },
    getCurrentShipType: (state) => {
      if (state.currentItem) {
        return state.getShipTypeName(state.currentItem.ship_type);
      }
      return "";
    },
    getShipTypeName: (state) => (type) => {
      const shipChoices = state.options.actions?.POST?.ship_type?.choices;
      if (shipChoices?.length) {
        return shipChoices.find((choice) => choice.value === type)?.display_name ?? '';
      }
      return "";
    }
  },
});
