<script setup>
import CalculationInputSideBar from "../components/calculation/CalculationInputSideBar.vue";
import { onMounted } from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import CalculationMooringSideBar from "../components/calculation/CalculationMooringSideBar.vue";
import MooringLayoutChart from "../components/chart/MooringLayoutChart.vue";
import OptimisationCardTable from "../components/optimisation/OptimisationCardTable.vue";
import { useRoute } from "vue-router/dist/vue-router";
import { useCalculationStore } from "@/stores/calculation";
import { useShipApiStore } from "@/stores/shipApi";
import { useProjectUserStore } from "@/stores/project_user";
import { useOptimisationStore } from "@/stores/optimisation";
import {useAuthorizationStore} from "@/stores/authorization";

const store = useCalculationStore();
const shipApiStore = useShipApiStore();
const projectUserStore = useProjectUserStore();
const optimisationStore = useOptimisationStore();
const authorizationStore = useAuthorizationStore();

const { items: authorizations } = storeToRefs(authorizationStore);
const { currentItem: currentProjectUser } = storeToRefs(projectUserStore);

const route = useRoute();

onMounted(async () => {
  await store.fetchOptions();
  await optimisationStore.fetchOptions();
  await store.fetchItem(route.params.calculationId);
  await shipApiStore.fetchItems();
  await projectUserStore.fetchItems({ project: route.params.id });
  projectUserStore.setCurrentItem();
  if (authorizations.value.length === 0) {
    await authorizationStore.fetchItems();
  }
  authorizationStore.setCurrentItem([currentProjectUser.value.role]);

});
</script>

<template>
  <v-layout full-height>
    <div class="chart fill-height">
      <CalculationInputSideBar></CalculationInputSideBar>
      <div style="margin-left: 55px" class="d-flex fill-height flex-column">
        <div style="height: 77%">
          <MooringLayoutChart class="fill-height"></MooringLayoutChart>
        </div>
        <div class="d-flex flex-column flex-grow-1">
          <OptimisationCardTable
            v-if="optimisationStore.fieldsList?.length"
          ></OptimisationCardTable>
        </div>
      </div>
    </div>
    <div class="sidebar fill-height">
      <CalculationMooringSideBar></CalculationMooringSideBar>
    </div>
  </v-layout>
</template>

<style scoped>
.chart {
  width: 55vw;
}

.sidebar {
  width: 45vw;
}
</style>
