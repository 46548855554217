<script setup>
import { useAuthorizationStore } from "@/stores/authorization";
import { computed } from "vue";
const authorizationStore = useAuthorizationStore();

const props = defineProps({
  authSource: { type: String, required: true },
  authModel: { type: [String, Array], required: true },
  modelValue: { type: Boolean, required: true, default: false },
  positionX: { type: Number, required: true, default: false },
  positionY: { type: Number, required: true, default: false },
  add: { type: Boolean, required: false, default: false },
  deleteItem: { type: Boolean, required: false, default: false },
  edit: { type: Boolean, required: false, default: false },
  duplicate: { type: Boolean, required: false, default: false },
});

const canEdit = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: props.authSource,
    allowed: "change_" + props.authModel,
  });
});

const canDelete = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: props.authSource,
    allowed: "delete_" + props.authModel,
  });
});

const canAdd = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: props.authSource,
    allowed: "add_" + props.authModel,
  });
});

const showMenu = computed(() => {
  return canAdd.value || canEdit.value || canDelete.value;
});
</script>
<template>
  <v-menu
    v-if="showMenu"
    :model-value="modelValue"
    :style="`position: absolute; left: ${positionX}px; top: ${positionY}px`"
    @update:modelValue="$emit('toggle')"
  >
    <v-list>
      <v-list-item v-if="add && canAdd" @click="$emit('add')">
        Add
      </v-list-item>
      <v-list-item v-if="edit && canEdit" @click="$emit('edit')">
        <v-icon class="mr-3"> mdi-pencil </v-icon>
        Edit
      </v-list-item>
      <v-list-item v-if="duplicate && canAdd" @click="$emit('duplicate')">
        <v-icon class="mr-3"> mdi-content-duplicate </v-icon>
        Duplicate
      </v-list-item>
      <v-list-item v-if="deleteItem && canDelete" @click="$emit('delete')">
        <v-icon class="mr-3"> mdi-delete </v-icon>
        <span>Delete</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
