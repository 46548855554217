<script setup>
import MooringPointsTableRow from "./MooringPointsTableRow.vue";
import ActionMenu from "../common/ActionMenu.vue";

import { storeToRefs } from "pinia/dist/pinia";

import { useMooringPointStore } from "@/stores/mooring_point";
import { useCalculationStore } from "@/stores/calculation";
import HelpTextTooltip from "../form/HelpTextTooltip.vue";
import { computed, ref } from "vue";
import { CONTINUOUS, ISLAND } from "@/components/calculation/berthTypes";
import NoDataBar from "@/components/calculationIndex/NoDataBar.vue";

const {
  menu,
  menuCoordinates,
  currentItem: currentMooringPoint,
} = storeToRefs(useMooringPointStore());
const { currentItem: currentCalculation } = storeToRefs(useCalculationStore());

const store = useMooringPointStore();

let hide = ref(true);

const mooringPointsWithHide = computed(() => {
  if (hide.value && currentCalculation.value.berth_type === CONTINUOUS) {
    return store.items.filter((item) => item.max_horizontal_angle);
  }
  return store.items;
});
</script>

<template>
  <div v-if="store.itemsAvailable" class="d-flex flex-column fill-height">
    <div class="pa-3">
      <v-chip
        v-if="currentCalculation.berth_type === CONTINUOUS"
        :ripple="true"
        link
        :prepend-icon="hide ? 'mdi-eye' : 'mdi-eye-off'"
        @click="hide = !hide"
      >
        {{ hide ? "Show" : "Hide" }} not used mooring points
      </v-chip>
    </div>
    <v-table
      class="pa-0 ma-0 d-flex flex-column overflow-y-auto pb-1"
      hover
      density="comfortable"
      fixed-header
      height="fill-height"
    >
      <thead>
        <tr>
          <th
            class="text-center"
            :colspan="currentCalculation.berth_type === ISLAND ? 2 : 1"
          ></th>
          <th
            class="text-center border-left-grey border-right-grey"
            colspan="3"
          >
            Coordinates
          </th>
          <th class="text-center" colspan="2">Maximum line angle</th>
        </tr>

        <tr>
          <th>
            <HelpTextTooltip
              color="black"
              text="'MD' stands for Mooring Dolphin, 'BD' stands for Berthing Dolphin"
            ></HelpTextTooltip>
            <span class="ml-1"> ID </span>
          </th>
          <th
            v-if="currentCalculation.berth_type === ISLAND"
            class="text-center"
            style="width: 150px"
          >
            Type
          </th>
          <th class="text-center border-left-grey">X [m]</th>
          <th class="text-center">Y [m]</th>
          <th class="text-center border-right-grey">Z [m CD]</th>
          <th class="text-center">Horizontal (°)</th>
          <th class="text-center">Vertical (°)</th>
        </tr>
      </thead>
      <tbody v-if="mooringPointsWithHide?.length">
        <template v-for="item in mooringPointsWithHide" :key="item.id">
          <MooringPointsTableRow :item="item" />
        </template>
      </tbody>
      <tbody v-else-if="store.itemsAvailable">
        <tr>
          <NoDataBar
            text="No mooring points available, click 'Show not used mooring points' to show them"
          >
          </NoDataBar>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6" class="text-center">No mooring points available</td>
        </tr>
      </tbody>
    </v-table>
  </div>
  <ActionMenu
    auth-source="ProjectUser"
    auth-model="calculation"
    :model-value="menu"
    :position-x="menuCoordinates.x"
    :position-y="menuCoordinates.y"
    delete-item
    @toggle="store.toggleMenu(undefined, item)"
    @delete="store.delete(currentMooringPoint)"
  />
</template>

<style scoped></style>
