<script setup>
import HeaderImage from "../components/projectIndex/HeaderImage.vue";
import ProjectIndexTitle from "../components/projectIndex/ProjectIndexTitle.vue";
import ProjectIndex from "../components/projectIndex/ProjectIndex.vue";
import CreateOrUpdateDialog from "../components/dialog/CreateOrUpdateDialog.vue";
import DeleteDialog from "../components/dialog/DeleteDialog.vue";
import { computed, onMounted, watch } from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import { useProjectStore } from "@/stores/project";

const { items, deleteDialog, currentItem, deleteItem } = storeToRefs(
  useProjectStore()
);

const width = computed(() => {
  switch (this.$vuetify.breakpoint.name) {
    case "xs":
      return "80%";
    case "sm":
      return "80%";
    case "md":
      return "50%";
    case "lg":
      return "50%";
    case "xl":
      return "50%";
  }
});

const store = useProjectStore();

watch(deleteDialog, () => {
  if (!deleteDialog.value) {
    store.setCurrentItem({});
  }
});

async function postOrUpdate() {
  const response = await store.postOrUpdate();
  if (response) {
    store.toggleCreateUpdateDialog();
  }
}

onMounted(async () => {
  if (!items || !items.length) {
    await store.fetchOptions();
    await store.fetchItems();
  }
});
</script>

<template>
  <div class="mb-16">
    <HeaderImage />
    <v-row class="mt-2">
      <v-col cols="1" sm="0"></v-col>
      <v-col cols="10">
        <ProjectIndexTitle />
        <ProjectIndex />
      </v-col>
      <v-col cols="1" sm="0"></v-col>
    </v-row>
  </div>
  <CreateOrUpdateDialog
    :store="store"
    :name="'project'"
    @save="postOrUpdate()"
  ></CreateOrUpdateDialog>
  <DeleteDialog :store="store" :name="deleteItem?.name"></DeleteDialog>
</template>

<script></script>
