<script setup>
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "vue-chartjs";
import * as chartShipConfig from "./chartShipConfig.js";

import { computed } from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import { useShipApiStore } from "@/stores/shipApi";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const { currentItem: currentShip } = storeToRefs(useShipApiStore());

const shipContourItems = computed(() => {
  return currentShip?.value["shipcontour_set"].sort(
    (a, b) => a["id"] - b["id"]
  );
});

const winchesItems = computed(() => {
  return currentShip ? currentShip.value["winch_set"] : [];
});

const fairleadsItems = computed(() => {
  return currentShip ? currentShip.value["fairlead_set"] : [];
});

const dataSets = computed(() => {
  return {
    datasets: [
      {
        label: "Fairleads",
        fill: false,
        borderColor: "teal",
        backgroundColor: "teal",
        pointBackgroundColor: "teal",
        data: fairleadsItems.value.map((item) => {
          return { x: item.x, y: item.y };
        }),
      },
      {
        label: "Winches",
        fill: false,
        pointStyle: "rect",
        borderColor: "olivedrab",
        backgroundColor: "olivedrab",
        pointBackgroundColor: "olivedrab",
        data: winchesItems.value.map((item) => {
          return { x: item.x, y: item.y };
        }),
      },
      {
        label: "Ship Contour",
        hover: {
          mode: false,
        },
        pointRadius: 1,
        borderWidth: 1,
        showLine: true,
        borderColor: null,
        backgroundColor: null,
        pointBackgroundColor: null,
        data: shipContourItems.value.map((item) => {
          return { x: item.x, y: item.y };
        }),
      },
    ],
  };
});

const options = computed(() => {
  const scales = { ...chartShipConfig.options.scales };
  scales.x = {
    ...chartShipConfig.options.scales.x,
    min: -chartScales.value,
    max: chartScales.value,
    stepSize: 10,
  };
  scales.y = {
    ...chartShipConfig.options.scales.y,
    min: -chartScales.value,
    max: chartScales.value,
    stepSize: 10,
  };
  return { ...chartShipConfig.options, scales };
});

const chartScales = computed(() => {
  if (currentShip.value["capacity"] > 150000) {
    return 200;
  } else if (currentShip.value["capacity"] > 20000) {
    return 150;
  } else if (currentShip.value["capacity"] > 5000) {
    return 100;
  } else {
    return 60;
  }
});
</script>

<template>
  <div class="fill-height">
    <Scatter :data="dataSets" :options="options" />
  </div>
</template>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
