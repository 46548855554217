export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      labels: {
        title: {
          color: null,
        },
      },
    },
    zoom: {
      pan: { enabled: true, modifierKey: null },
      zoom: {
        wheel: {
          enabled: true,
          modifierKey: "ctrl",
        },
        pinch: {
          enabled: true,
        },
        mode: "xy",
      },
    },
    legend: {
      position: "top",
      align: "center",
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
  },
  scales: {
    x: {
      ticks: {
        display: true,
        z: 1,
        showLabelBackdrop: true,
        minRotation: 0,
        maxRotation: 0,
        crossAlign: "near",
        color: function (context) {
          if (context.tick.value === 0) {
            return "rgb(0,0,0)";
          } else {
            return "rgba(0,0,0,0.75)";
          }
        },
        callback: function (value, index, ticks) {
          if (value === 0) {
            return [value, "Spotting line"];
          } else {
            return value.toFixed(0);
          }
        },
      },
      grid: {
        color: function (context) {
          if (context.tick.value === 0) {
            return "rgba(0,0,0,0.25)";
          } else {
            return "rgba(0,0,0,0.1)";
          }
        },
        lineWidth: function (context) {
          if (context.tick.value === 0) {
            return 2;
          } else {
            return 1;
          }
        },
      },
    },
    y: {
      position: "right",
      ticks: {
        display: true,
        mirror: true,
        padding: 30,
        showLabelBackdrop: true,
        z: 1,
        color: function (context) {
          if (context.tick.value === 0) {
            return "rgb(0,0,0)";
          } else {
            return "rgba(0,0,0,0.75)";
          }
        },
        callback: function (value, index, ticks) {
          if (value === 0) {
            return "Berthing line         " + value;
          } else {
            return value.toFixed(0);
          }
        },
      },
      grid: {
        color: function (context) {
          if (context.tick.value === 0) {
            return "rgba(0,0,0,0.25)";
          } else {
            return "rgba(0,0,0,0.1)";
          }
        },
        lineWidth: function (context) {
          if (context.tick.value === 0) {
            return 2;
          } else {
            return 1;
          }
        },
      },
    },
  },
};
