<script setup>
import Field from "../form/Field.vue";
import ChoiceField from "../form/ChoiceField.vue";

const { emit } = useEventsBus();

import { useMooringPointStore } from "@/stores/mooring_point";
import useEventsBus from "../../bus/eventBus";

import { MOORING_POINT_LABEL } from "../chart/chartLabels.js";
import { ISLAND } from "@/components/calculation/berthTypes";
import { useCalculationStore } from "@/stores/calculation";
import { storeToRefs } from "pinia/dist/pinia";
import { computed } from "vue";
import { useAuthorizationStore } from "@/stores/authorization";

const store = useMooringPointStore();
const authorizationStore = useAuthorizationStore();

const { currentItem: currentCalculation } = storeToRefs(useCalculationStore());

const props = defineProps({
  item: { type: Object, required: true },
});

const canEdit = computed(() =>
  authorizationStore.hasServicePermission({
    authSource: "ProjectUser",
    allowed: "change_mooringpoint",
  })
);
</script>

<template>
  <tr
    @contextmenu.prevent="
      store.toggleMenu({ x: $event.clientX, y: $event.clientY }, item)
    "
  >
    <td style="width: 1%">
      <v-chip
        v-ripple
        class="clickable"
        label
        size="x-small"
        :color="item.color"
        @click.stop="
          emit('chartEvent', { id: item.id, label: MOORING_POINT_LABEL })
        "
      >
        {{ item.name }}
      </v-chip>
    </td>
    <td v-if="currentCalculation.berth_type === ISLAND">
      <ChoiceField
        :disabled="!canEdit"
        :store="store"
        :field="store.fields['type']"
        :item="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        center
        @update="store.update(props.item)"
      ></ChoiceField>
    </td>
    <td class="border-left-grey">
      <Field
        :disabled="!canEdit"
        :store="store"
        :field="store.fields['x']"
        :item="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        center
        font-size="15px"
        :step="0.5"
        @update="store.update(props.item)"
      ></Field>
    </td>
    <td>
      <Field
        :disabled="!canEdit"
        :store="store"
        :field="store.fields['y']"
        :item="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        center
        font-size="15px"
        :step="0.5"
        no-update-on-error
        @update="store.update(props.item)"
      ></Field>
    </td>
    <td class="border-right-grey">
      <Field
        :disabled="!canEdit"
        :store="store"
        :field="store.fields['z']"
        :item="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        center
        font-size="15px"
        :step="0.5"
        @update="store.update(props.item)"
      ></Field>
    </td>
    <td
      class="text-center"
      :style="`border-bottom-width: ${
        item.max_vertical_color ? 2 : 1
      }px;border-bottom-style: solid; border-bottom-color: ${
        item.max_horizontal_color
          ? item.max_horizontal_color
          : 'rgba(0, 0, 0, 0.05)'
      };`"
    >
      <div>
        {{ item.max_horizontal_angle?.toFixed(2) }}
      </div>
    </td>
    <td
      class="text-center"
      :style="`border-bottom-width: ${
        item.max_vertical_color ? 2 : 1
      }px;border-bottom-style: solid;border-bottom-color: ${
        item.max_vertical_color
          ? item.max_vertical_color
          : 'rgba(0, 0, 0, 0.05)'
      };`"
    >
      <div>
        {{ item.max_vertical_angle?.toFixed(2) }}
      </div>
    </td>
  </tr>
</template>

<style scoped></style>
