<script setup>
import { computed, onMounted, ref, watch } from "vue";
import HelpTextTooltip from "./HelpTextTooltip.vue";
import { toNumber } from "lodash";

const props = defineProps({
  item: { type: Object, required: true },
  field: { type: Object, required: true },
  store: { type: Object, required: true },
  hideDetails: { type: Boolean, required: false, default: false },
  center: { type: Boolean, required: false, default: false },
  shrink: { type: Boolean, required: false, default: false },
  warning: { type: Boolean, required: false, default: false },
  warningRules: { required: false, default: false },
  customErrorRules: { required: false, default: false },
  noLabel: { type: Boolean, required: false, default: false },
  customLabel: { type: String, required: false, default: null },
  noUpdateOnError: { type: Boolean, required: false, default: false },
  noPadding: { type: Boolean, required: false, default: false },
  noHelpText: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  density: { type: String, required: false, default: "default" },
  prependInnerIcon: { type: String, required: false, default: null },
  variant: { type: String, required: false, default: "filled" },
  step: { type: Number, required: false, default: 1 },
  fontSize: { type: String, required: false },
});
const emit = defineEmits(["update", "click:prepend-inner"]);

const typeMapping = ref({
  float: "number",
  integer: "number",
  string: "text",
});

const span = ref(null);
const fieldRef = ref(null);

watch(props.store, () => {});

let error = ref(false);

function setParameter(e) {
  let value = e.target.value;
  const validation = validate(value);

  if (!isNaN(value)) {
    value = toNumber(value);
  }
  props.store.setError(!validation);
  error.value = !validation;
  if (!validation && props.noUpdateOnError) {
    return;
  }
  props.store.setParameter(props.item, props.field.parameter, value);
  if (error.value) {
    return;
  }
  emit("update", value);
}

const value = computed({
  get() {
    let value = props.item[props.field.parameter];
    if (typeof value === "number") {
      value = value.toFixed(2);
    }
    return value;
  },
  set() {
    // Required to have an empty set, als 'v-model' will give errors. 'value' instead of 'v-model' does not do the job as it gives syncing issues.
  },
});

const shrinkStyle = computed({
  get() {
    return props.shrink ? `width:${value.value?.length - 1}.5rem` : "";
  },
});

const showErrorUnderline = computed({
  get() {
    return props.density === "compact" && props.hideDetails && error.value;
  },
});

const noPaddingClass = computed({
  get() {
    return props.noPadding ? "no-padding" : "";
  },
});

const fieldInputCenterClass = computed({
  get() {
    return props.center ? "field-input-center" : "";
  },
});

const errorUnderlineClass = computed({
  get() {
    return showErrorUnderline.value ? "border-bottom-red" : "";
  },
});

const fontRatio = ref(1);

const rulesDef = computed({
  get() {
    const required =
      "required" in props.field && props.field.required
        ? rules.value.required
        : true;
    const maxValue = "max_value" in props.field ? rules.value.maxValue : true;
    const minValue = "min_value" in props.field ? rules.value.minValue : true;
    const customErrorRules = props.customErrorRules
      ? props.customErrorRules
      : [];
    return [required, maxValue, minValue, ...customErrorRules];
  },
});

const warningRulesDef = computed({
  get() {
    let message = "";
    props.warningRules.forEach((rule) => {
      if ("min_value" in rule && value.value < rule.min_value) {
        message = rule.message;
      }
      if ("max_value" in rule && value.value > rule.max_value) {
        message = rule.message;
      }
    });
    return message;
  },
});

function validate(value) {
  return rulesDef.value.every((func) => {
    return !(func !== true && func(value) !== true);
  });
}

const rules = ref({
  required: (value) => !!value || "Required.",
  maxValue: (value) =>
    value <= props.field.max_value ||
    `The max value is ${props.field.max_value}.`,
  minValue: (value) =>
    value >= props.field.min_value ||
    `The min value is ${props.field.min_value}.`,
});

// const type = computed({
//   get() {
//     return props.item[props.field.parameter];
//   },
// });

onMounted(() => {
  try {
    if (
      span.value.clientWidth >
      span.value.parentElement.parentElement.parentElement.clientWidth
    ) {
      const extraWidthParent = 20;
      fontRatio.value =
        (span.value.parentElement.parentElement.parentElement.clientWidth +
          extraWidthParent) /
        span.value.clientWidth;
    }
  } catch {
    /* empty */
  }
});
</script>

<template>
  <div v-if="field" @click.stop>
    <v-textarea
      v-if="field.field_type === 'textfield'"
      v-model="value"
      :disabled="disabled"
      color="primary"
      :label="field.label"
      :type="typeMapping[field.type]"
      :hide-details="hideDetails"
      @change="setParameter($event)"
    >
    </v-textarea>

    <v-text-field
      v-else
      ref="fieldRef"
      v-model="value"
      :prepend-inner-icon="prependInnerIcon"
      :step="step"
      :rules="rulesDef"
      color="primary"
      :class="`${noPaddingClass} ${errorUnderlineClass} ${fieldInputCenterClass} font-size`"
      :disabled="disabled"
      :variant="variant"
      :density="density"
      :type="typeMapping[field.type]"
      :hide-details="hideDetails"
      :style="`white-space: nowrap; ${shrinkStyle}`"
      @click:prepend-inner="emit('click:prepend-inner')"
      @click.stop
      @change="setParameter($event)"
    >
      <template #label>
        <span ref="span" :style="`font-size: ${fontRatio}em;`">
          {{ !noLabel ? (customLabel ? customLabel : field.label) : null }}
        </span>
      </template>
      <template #append-inner>
        <div class="pt-1">
          <HelpTextTooltip
            v-if="field.help_text && !noHelpText"
            :text="field.help_text"
          />
        </div>
      </template>
      <template #details="{ isValid }">
        <template v-if="isValid">
          <div
            v-if="isValid.value && props.warning"
            class="v-messages__message"
            style="color: darkorange"
          >
            {{ warningRulesDef }}
          </div>
        </template>
      </template>
    </v-text-field>
  </div>
</template>

<style scoped>
.no-padding :deep(.v-field__input) {
  padding: 0;
}

.v-text-field :deep(.v-input__details) {
  padding-left: 0;
  padding-bottom: 2px;
  padding-top: 2px;
  min-height: 12px;
  word-break: break-word;
}

.v-text-field :deep(.v-messages__message) {
  padding-left: 4px;
  position: fixed;
  background-color: white;
}

.field-input-center :deep(.v-field__input) {
  text-align: center;
}

.font-size :deep(.v-field__input) {
  font-size: v-bind(fontSize);
}
</style>
