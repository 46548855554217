<script setup>
import Field from "./Field.vue";
import { storeToRefs } from "pinia/dist/pinia";

const props = defineProps({
  store: { type: Object, required: true },
});

const { newItem } = storeToRefs(props.store);
</script>

<template>
  <v-form v-for="field in store.fieldsFiltered">
    <Field :field="field" :item="newItem" :store="store" />
  </v-form>
</template>
