// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import { customSVGs } from "@/components/icons/customIcons";

const rhdhvLight = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#0186A7",
    secondary: "#0186A7",
    speckleColor: "#047EFB",
  },
};

export default createVuetify(
  {
    theme: {
      defaultTheme: "rhdhvLight",
      themes: {
        rhdhvLight,
      },
    },
    icons: {
      iconfont: "mdiSvg",
      sets: {
        custom: customSVGs,
      },
    },
  }

  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
);
