<script setup>
import Field from "@/components/form/Field.vue";

import { useFairleadStore } from "@/stores/fairlead";
import { useMooringPointStore } from "@/stores/mooring_point";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import { useWinchStore } from "@/stores/winch";
import { useShipStore } from "@/stores/ship";

const store = useFairleadStore();

const { items: mooringPointItems } = storeToRefs(useMooringPointStore());
const { items: shipItems } = storeToRefs(useShipStore());
const { items: winchItems } = storeToRefs(useWinchStore());

import {
  FAIRLEAD_LABEL,
  WINCH_LABEL,
  MOORING_POINT_LABEL,
} from "../chart/chartLabels";
import useEventsBus from "../../bus/eventBus";
import { useAuthorizationStore } from "@/stores/authorization";

const { emit } = useEventsBus();

const props = defineProps({
  item: { type: Object, required: true },
  index: { type: Number, required: true },
});

const mooringPointMenuOpen = ref(false);
const lineTypeMenuOpen = ref(false);

const mooringPoint = computed(() => {
  return mooringPointItems.value.find(
    (mooringPointItem) => props.item.mooring_point === mooringPointItem.id
  );
});

const mooringPointName = computed(() => {
  return mooringPoint.value ? mooringPoint.value.name : "MP0";
});

const winch = computed(() => {
  return winchItems.value.find(
    (winchItem) => props.item.winch === winchItem.id
  );
});

const ship = computed(() => {
  return shipItems.value.find((item) => props.item.ship === item.id);
});

const xWithEccentricity = computed(() => {
  return (props.item.x + ship.value?.eccentricity).toFixed(1);
});

async function setLineType(lineType) {
  store.setParameter(props.item, "line_type", lineType);
  await store.update(props.item);
}

async function setMooringPoint(item) {
  if (props.item.mooring_point === item.id) {
    store.setParameter(props.item, "mooring_point", null);
    store.setParameter(props.item, "horizontal_angle", NaN);
    store.setParameter(props.item, "horizontal_color", null);
    store.setParameter(props.item, "vertical_angle", NaN);
    store.setParameter(props.item, "vertical_color", null);
  } else {
    store.setParameter(props.item, "mooring_point", item.id);
  }
  await store.update(props.item);
}

const authorizationStore = useAuthorizationStore();

const canEdit = computed(() =>
  authorizationStore.hasServicePermission({
    authSource: "ProjectUser",
    allowed: "change_fairlead",
  })
);
</script>

<template>
  <tr
    @contextmenu.prevent="
      store.toggleMenu({ x: $event.clientX, y: $event.clientY }, item)
    "
  >
    <td class="pr-0 text-left">{{ index + 1 }}</td>
    <td style="width: 1%; font-size: 12px" class="pr-0">
      <v-chip
        v-ripple
        class="clickable"
        label
        size="x-small"
        :color="item.winch_color"
        @click.stop="
          emit('chartEvent', { id: item.id, label: FAIRLEAD_LABEL + ship.id })
        "
      >
        F{{ item?.number }}
      </v-chip>
    </td>
    <td style="width: 1%" class="pr-0">
      <v-chip
        v-ripple
        class="clickable"
        label
        size="x-small"
        :color="winch?.color"
        @click.stop="
          emit('chartEvent', { id: winch.id, label: WINCH_LABEL + ship.id })
        "
      >
        W{{ winch?.number }}
      </v-chip>
    </td>
    <td class="pr-0">
      <v-chip
        v-ripple
        class="clickable"
        label
        link
        size="x-small"
        :color="mooringPoint ? mooringPoint?.color : null"
        :append-icon="
          canEdit
            ? mooringPointMenuOpen
              ? 'mdi-chevron-up'
              : `mdi-chevron-down`
            : ''
        "
        @click="mooringPointMenuOpen = !mooringPointMenuOpen"
      >
        {{ mooringPointName }}
        <v-menu
          v-model="mooringPointMenuOpen"
          :open-on-click="true"
          :disabled="!canEdit"
          activator="parent"
          max-height="400"
        >
          <v-list>
            <v-list-item
              v-for="(item, index) in mooringPointItems"
              :key="index"
              :value="index"
              @click="setMooringPoint(item)"
            >
              <v-chip
                v-ripple
                label
                size="x-small"
                :color="item.color"
                :append-icon="item === mooringPoint ? 'mdi-close-circle' : null"
                @click.right.prevent="
                  emit('chartEvent', {
                    id: item.id,
                    label: MOORING_POINT_LABEL,
                  })
                "
              >
                {{ item.name }}
              </v-chip>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-chip>
    </td>
    <td>
      <!--      TODO add this to ChoiceField component -->
      <div class="clickable">
        <div>
          {{ item.line_type }}
          <v-icon class="float-right pt-2" size="x-small"
            >{{
              canEdit
                ? lineTypeMenuOpen
                  ? "mdi-chevron-up"
                  : `mdi-chevron-down`
                : ""
            }}
          </v-icon>
        </div>
        <v-menu v-if="canEdit" v-model="lineTypeMenuOpen" activator="parent">
          <v-list>
            <v-list-item
              v-for="(choice, index) in store.fields['line_type'].choices"
              :key="index"
              :value="index"
              @click="setLineType(choice.display_name)"
            >
              <div>{{ choice.display_name }}</div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </td>

    <td class="pr-0 border-left-grey">{{ xWithEccentricity }}</td>
    <td class="pr-0">{{ item.y?.toFixed(1) }}</td>
    <td class="border-right-grey">{{ item.z?.toFixed(1) }}</td>
    <td class="border-right-grey pr-1 pl-1">
      <Field
        :store="store"
        shrink
        :field="store.fields['minimum_horizontal_angle']"
        :item="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-padding
        no-help-text
        center
        font-size="14px"
        @update="store.update(item)"
      ></Field>
      <!--      {{ item.minimum_horizontal_angle?.toFixed(1) }}-->
    </td>
    <td
      class="border-right-grey"
      :style="`border-bottom-width: ${
        mooringPoint && item.line_length_color ? 2 : 1
      }px;border-bottom-style: solid;border-bottom-color: ${
        mooringPoint ? item.line_length_color : null
      };`"
    >
      {{ item.line_length?.toFixed(1) }}
    </td>
    <td
      class="pr-0"
      :style="`border-bottom-width: ${
        mooringPoint && item.horizontal_color ? 2 : 1
      }px;border-bottom-style: solid;border-bottom-color: ${
        mooringPoint ? item.horizontal_color : null
      };`"
    >
      <div v-if="mooringPoint">
        {{ Math.round(item.horizontal_angle * 100) / 100 }}
      </div>
    </td>
    <td
      class="pr-0"
      :style="`border-bottom-width: ${
        mooringPoint && item.vertical_color ? 2 : 1
      }px;border-bottom-style: solid;border-bottom-color: ${
        mooringPoint ? item.vertical_color : null
      };`"
    >
      <div v-if="mooringPoint">
        {{ Math.round(item.vertical_angle * 100) / 100 }}
      </div>
    </td>
  </tr>
</template>

<style scoped>
.td {
  text-align: center;
}
</style>
