<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useAuthorizationStore } from "@/stores/authorization";
const authorizationStore = useAuthorizationStore();

const props = defineProps({
  authSource: { type: String, required: true },
  allowed: { type: [String, Array], required: true },
  itemClass: { type: String, default: "ml-3 mb-1" },
  appendIcon: { type: String, default: "mdi-plus" },
  color: { type: String, default: "primary" },
  size: { type: String, default: "small" },
  variant: { type: String, default: "outlined" },
  width: { type: String, required: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
});

// Emits custom events
const emit = defineEmits(["click"]);

const handleButtonClick = () => {
  emit("click");
};

const isAuthorized = computed(() => {
  return checkAuthorization();
});

const checkAuthorization = () => {
  return authorizationStore.hasServicePermission({
    authSource: props.authSource,
    allowed: props.allowed,
  });
};
</script>

<template>
  <v-btn
    v-if="isAuthorized"
    :disabled="disabled"
    :loading="loading"
    :width="width"
    :class="itemClass"
    :append-icon="appendIcon"
    :color="color"
    :size="size"
    :variant="variant"
    @click="handleButtonClick"
  >
    <slot></slot>
  </v-btn>
</template>
