class Vector3D {
  constructor(x, y, z) {
    this.x = x;
    this.y = y;
    this.z = z;
  }

  dot(other) {
    return this.x * other.x + this.y * other.y + this.z * other.z;
  }

  length() {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }
}

function angleInDegreesHorizontal(item1, item2) {
  let angle = angleInDegrees(item1.x, item2.x, item1.y, item2.y);
  angle = Math.abs(angle - 90);
  angle = Math.round(angle * 100) / 100;
  return angle;
}

function angleInDegrees(x1, x2, y1, y2) {
  return (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
}

function angleInDegreesVertical(item1, item2) {
  const x1 = item1.x,
    y1 = item1.y,
    z1 = item1.z;
  const x2 = item2.x,
    y2 = item2.y,
    z2 = item2.z;
  const vector = new Vector3D(x2 - x1, y2 - y1, z2 - z1);
  const dot = vector.dot(new Vector3D(0, 0, 1));
  let angle = (Math.acos(dot / vector.length()) * 180) / Math.PI;
  angle = Math.abs(angle - 90);
  angle = Math.round(angle * 100) / 100;
  return angle;
}

function horizontalAngleFairlead(mooringPoint, fairlead) {
  if (!mooringPoint) {
    return NaN;
  }
  let angle = angleInDegreesHorizontal(mooringPoint, fairlead);
  if (fairlead.line_type === "Spring") {
    angle = angle - 90;
  }
  angle = Math.abs(angle);
  angle = Math.round(angle * 100) / 100;
  return angle;
}

export {
  angleInDegreesHorizontal,
  angleInDegreesVertical,
  horizontalAngleFairlead,
};
