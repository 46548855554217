<script setup>
import { computed, onMounted } from "vue";
import { useShipApiStore } from "@/stores/shipApi";
import { storeToRefs } from "pinia/dist/pinia";

const store = useShipApiStore();

const props = defineProps({
  item: { type: Object, required: true },
});
const { selectedItems, currentItem } = storeToRefs(useShipApiStore());

const selected = computed({
  get() {
    return !!selectedItems.value.includes(props.item);
  },
  set() {
    store.toggleSelectedItem(props.item);
  },
});

const rowItem = computed({
  get() {
    return props.item;
  },
  set() {
    return null;
  },
});

function setCurrentShip() {
  store.setCurrentItem(props.item);
}

function resetValue(property) {
  const originalProperty = `original_${property}`;
  store.setParameter(props.item, property, props.item[originalProperty]);
}

function getFocusLineColor(id) {
  return currentItem.value.id === id ? "rgb(var(--v-theme-primary)" : "white";
}
</script>

<template>
  <tr v-ripple style="cursor: pointer" @click="setCurrentShip()">
    <td
      class="pl-2"
      :style="`border-left: solid 5px ${getFocusLineColor(item.id)}`"
    >
      <v-checkbox
        v-model="selected"
        style="margin: -16px 0"
        color="primary"
        hide-details
        class="shrink"
        @click.stop=""
      ></v-checkbox>
    </td>
    <td class="pr-0 pl-1 text-center">{{ item.fulltype }}</td>
    <td class="text-center">{{ item.capacity }}</td>
    <td class="border-left-grey text-center pr-0 pl-2">
      <v-text-field
        v-model="rowItem['length_overall']"
        type="number"
        class="font-size-small field-input-center no-padding"
        font-size="14px"
        prepend-inner-icon="mdi-refresh"
        hide-details
        :store="store"
        :field="store.fields['length_overall']"
        density="compact"
        variant="plain"
        center
        @click:prepend-inner="resetValue('length_overall')"
        @change="
          store.setParameter(rowItem, 'length_overall', $event.target.value)
        "
      >
      </v-text-field>
    </td>
    <td class="text-center pr-1 pl-2">
      <v-text-field
        v-model="rowItem['breadth']"
        type="number"
        class="font-size-small field-input-center no-padding"
        font-size="14px"
        prepend-inner-icon="mdi-refresh"
        hide-details
        :store="store"
        :field="store.fields['breadth']"
        density="compact"
        variant="plain"
        center
        @click:prepend-inner="resetValue('breadth')"
        @change="store.setParameter(rowItem, 'breadth', $event.target.value)"
      ></v-text-field>
    </td>
    <td class="border-left-grey text-center pr-0 pl-2">
      <v-text-field
        v-model="rowItem['laden_draught']"
        type="number"
        class="font-size-small field-input-center no-padding"
        font-size="14px"
        prepend-inner-icon="mdi-refresh"
        hide-details
        :store="store"
        :field="store.fields['laden_draught']"
        density="compact"
        variant="plain"
        center
        @click:prepend-inner="resetValue('laden_draught')"
        @change="
          store.setParameter(rowItem, 'laden_draught', $event.target.value)
        "
      ></v-text-field>
    </td>
    <td class="pr-1 pl-3">
      <v-text-field
        v-model="rowItem['ballasted_draught']"
        type="number"
        class="font-size-small field-input-center no-padding"
        font-size="14px"
        prepend-inner-icon="mdi-refresh"
        hide-details
        :store="store"
        :field="store.fields['ballasted_draught']"
        density="compact"
        variant="plain"
        center
        @click:prepend-inner="resetValue('ballasted_draught')"
        @change="
          store.setParameter(rowItem, 'ballasted_draught', $event.target.value)
        "
      ></v-text-field>
    </td>
    <td class="border-left-grey text-center pr-0 pl-2">
      <v-text-field
        v-model="rowItem['depth_main_deck_to_keel']"
        type="number"
        class="font-size-small field-input-center no-padding"
        font-size="14px"
        prepend-inner-icon="mdi-refresh"
        hide-details
        :store="store"
        :field="store.fields['depth_main_deck_to_keel']"
        density="compact"
        variant="plain"
        center
        @click:prepend-inner="resetValue('depth_main_deck_to_keel')"
        @change="
          store.setParameter(
            rowItem,
            'depth_main_deck_to_keel',
            $event.target.value
          )
        "
      ></v-text-field>
    </td>
    <td class="text-center pr-1 pl-3">
      <v-text-field
        v-model="rowItem['depth_forecastle_deck_to_keel']"
        type="number"
        class="font-size-small field-input-center no-padding"
        font-size="14px"
        prepend-inner-icon="mdi-refresh"
        hide-details
        :store="store"
        :field="store.fields['depth_forecastle_deck_to_keel']"
        density="compact"
        variant="plain"
        center
        @click:prepend-inner="resetValue('depth_forecastle_deck_to_keel')"
        @change="
          store.setParameter(
            rowItem,
            'depth_forecastle_deck_to_keel',
            $event.target.value
          )
        "
      ></v-text-field>
    </td>
    <td class="text-center pr-1 pl-3">
      <v-text-field
        v-model="rowItem['depth_poop_deck_to_keel']"
        type="number"
        class="font-size-small field-input-center no-padding"
        font-size="14px"
        prepend-inner-icon="mdi-refresh"
        hide-details
        :store="store"
        :field="store.fields['depth_poop_deck_to_keel']"
        density="compact"
        variant="plain"
        center
        @click:prepend-inner="resetValue('depth_poop_deck_to_keel')"
        @change="
          store.setParameter(
            rowItem,
            'depth_poop_deck_to_keel',
            $event.target.value
          )
        "
      ></v-text-field>
    </td>
    <td class="border-left-grey text-center">
      {{ item.aftward_parallel_body_length }}
    </td>
    <td :style="`border-right: solid 5px ${getFocusLineColor(item.id)}`">
      {{ item.forward_parallel_body_length }}
    </td>
  </tr>
</template>
<style scoped>
.td {
  text-align: center;
}

.no-padding :deep(.v-field__input) {
  padding: 0;
}

.no-padding :deep(.v-field__append-inner),
:deep(.v-field__prepend-inner) {
  padding: 0;
  padding-top: 3px;
  padding-right: 6px;
}

.font-size-small :deep(.v-field__input) {
  font-size: 14px;
}

.field-input-center :deep(.v-field__input) {
  text-align: center;
}

.v-text-field :deep(.v-input__details) {
  padding-left: 0;
  padding-bottom: 2px;
  padding-top: 2px;
  min-height: 12px;
  word-break: break-word;
}

.no-padding :deep(.v-field__input) {
  padding: 0;
}
</style>
