import { createRouter, createWebHistory } from "vue-router";
import ProjectIndexView from "../views/ProjectIndexView.vue";
import ProjectDetailView from "../views/ProjectDetailView.vue";
import UserManagementView from "../views/UserManagementView.vue";
import CalculationDetailView from "../views/CalculationDetailView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import { useProjectStore } from "@/stores/project";
import { useCalculationStore } from "@/stores/calculation";
import CalculationIndexView from "@/views/CalculationIndexView.vue";
import useEventsBus from "@/bus/eventBus";
import { inject } from "vue";
import { authRoutes } from "@rhdhv/vue-authentication-middleware";
import { storeToRefs } from "pinia/dist/pinia";

const { emit } = useEventsBus();
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/:pathMatch(.*)*",
      component: NotFoundView,
    },
    {
      path: "/",
      name: "home",
      redirect: {
        name: "auth-login",
      },
    },
    authRoutes,
    {
      path: "/projects",
      name: "project-index",
      component: ProjectIndexView,
      beforeEnter: (to, from) => {
        emit("authenticateSpeckle");
        const store = useProjectStore();
        store.resetAll();
        return true;
      },
    },
    {
      path: "/project/:id",
      name: "project-detail",
      component: ProjectDetailView,
      redirect: {
        name: "calculation-index",
      },
      children: [
        {
          path: "/project/:id",
          name: "calculation-index",
          component: CalculationIndexView,
        },
        {
          path: "/project/:id/user-management",
          name: "user-management",
          component: UserManagementView,
        },
      ],
      beforeEnter: (to, from) => {
        const store = useCalculationStore();
        store.resetAll();
        return true;
      },
    },
    {
      path: "/project/:id/calculation/:calculationId",
      name: "calculation",
      component: CalculationDetailView,
      beforeEnter: async (to, from) => {
        return true;
      },
    },
  ],
});

router.beforeEach(async (to, from) => {
  const authStore = inject("useAuthStore");
  const { user } = storeToRefs(authStore);
  if (!user?.value?.id) {
    const userResponse = await authStore.reAuthenticate();
    await authStore.authenticateShipApi();

    if (userResponse) {
      authStore.$patch({
        user: userResponse.user,
      });
    }
  }
  if (to.name.startsWith("auth-")) {
    if (user.value) {
      return { name: "project-index" };
    }
    return true;
  }
  // check auth and apply login redirect
  if (!user.value) {
    authStore.loginRedirect = to;
    return { name: "auth-login" };
  }
  return true;
});

export default router;
