<script setup>
import Form from "../form/Form.vue";
import { computed } from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import useEventsBus from "../../bus/eventBus";

const { emit } = useEventsBus();

const props = defineProps({
  name: { type: String, required: false },
  store: { type: Object, required: true },
});

const {
  createUpdateDialog: dialog,
  writeLoading,
  currentItem,
} = storeToRefs(props.store);

const createOrUpdate = computed(() => {
  return currentItem.value["id"]
    ? "Update"
    : currentItem.value["duplicated_id"]
    ? "Duplicate"
    : "Create";
});
</script>
<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="pt-6">{{ createOrUpdate }} {{ name }}</v-card-title>
      <v-card-text>
        <Form :store="store" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :loading="writeLoading"
          color="primary"
          text
          @click="$emit('save')"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
