<script setup>
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

import { useRoute } from "vue-router/dist/vue-router";
import ProjectDetailSideBar from "../components/projectDetail/ProjectDetailSideBar.vue";
import { useProjectUserStore } from "@/stores/project_user";
import { useProjectStore } from "@/stores/project";
import _ from "lodash";
import {useAuthorizationStore} from "@/stores/authorization";

const route = useRoute();

const projectStore = useProjectStore();
const projectUserStore = useProjectUserStore();
const authorizationStore = useAuthorizationStore();

const { items: authorizations } = storeToRefs(authorizationStore);
const { currentItem: currentProject } = storeToRefs(useProjectStore());
const { hasAuthModelInStore, currentItem: currentProjectUser } = storeToRefs(projectUserStore);

onMounted(async () => {
  await projectUserStore.fetchItems({ project: route.params.id });
  projectUserStore.setCurrentItem();
  if (authorizations.value.length === 0) {
    await authorizationStore.fetchItems();
  }
  authorizationStore.setCurrentItem([currentProjectUser.value.role]);
  if (!currentProject.value || _.isEmpty(currentProject.value)) {
    await projectStore.fetchItem(route.params.id);
  }
});
</script>
<template>
  <ProjectDetailSideBar />
  <v-row v-if="hasAuthModelInStore" class="ml-4 mr-4">
    <v-col class="mt-4">
      <router-view></router-view>
    </v-col>
  </v-row>
</template>
