<script setup>
import { useProjectStore } from "@/stores/project";
import { useAuthorizationStore } from "@/stores/authorization";
import { computed } from "vue";

const props = defineProps({
  item: { type: Object, required: true },
});

const store = useProjectStore();
const authorizationStore = useAuthorizationStore();

const canDelete = computed(() =>
  authorizationStore.hasItemPermission({
    authInstance: props.item.current_project_user,
    allowed: "delete_project",
  })
);

const canEdit = computed(() =>
  authorizationStore.hasItemPermission({
    authInstance: props.item.current_project_user,
    allowed: "change_project",
  })
);

const showMenu = computed(() => canDelete.value || canEdit.value);

function toggleDialog() {
  const item = JSON.parse(JSON.stringify(props.item));
  store.setCurrentItem(item);
  store.toggleCreateUpdateDialog(item);
}
</script>

<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        v-if="showMenu"
        icon="mdi-dots-vertical"
        size="small"
        flat
        v-bind="props"
        @mousedown.stop
      >
      </v-btn>
    </template>

    <v-list density="compact">
      <v-list-item v-if="canEdit" @click="toggleDialog()">
        <v-list-item-title>
          <v-row>
            <v-col cols="4">
              <v-icon> mdi-pencil</v-icon>
            </v-col>
            <v-col> Edit</v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="canDelete" @click="store.toggleDeleteDialog(item)">
        <v-list-item-title>
          <v-row>
            <v-col cols="4">
              <v-icon> mdi-delete</v-icon>
            </v-col>
            <v-col> Delete</v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
