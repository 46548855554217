<script setup>
import { storeToRefs } from "pinia/dist/pinia";

import { useCalculationStore } from "@/stores/calculation";
import { useShipStore } from "@/stores/ship";
import ShipTableRow from "./ShipTableRow.vue";
import ActionMenu from "../common/ActionMenu.vue";
import { useShipApiStore } from "@/stores/shipApi";
import { computed } from "vue";

const store = useShipStore();
const shipApiStore = useShipApiStore();

const { currentItem } = storeToRefs(useCalculationStore());

const {
  items,
  menu,
  currentItem: currentShip,
  menuCoordinates: shipMenuCoordinates,
} = storeToRefs(useShipStore());

const sortedItemsOnCapacity = computed(() => {
  return items.value.sort((a, b) => {
    return b.capacity - a.capacity;
  });
});
</script>

<template>
  <div
    v-if="store.itemsAvailable && store.optionsAvailable"
    class="d-flex flex-column fill-height"
  >
    <v-table
      ref="table"
      class="ma-0 d-flex flex-column overflow-y-auto"
      fixed-header
      height="fill-height"
    >
      <thead>
        <tr>
          <th class="pr-0"></th>
          <th class="text-center">
            Capacity <small> [{{ shipApiStore.getCapacityUnit }}]</small>
          </th>
          <th class="text-center">
            <small> Centre manifold </small>Eccentricity [m]
          </th>
          <th class="text-center">Length overall<small> [m]</small></th>
          <th class="text-center">Breadth<small> [m]</small></th>

          <th class="text-center" colspan="2">
            <v-row>
              <v-col class="pa-0"><small>Draught [m]</small></v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0 pr-1"> Laden</v-col>
              <v-col class="pa-0">Ballasted</v-col>
            </v-row>
          </th>

          <th class="text-center">Depth [m]</th>
          <th class="text-center" colspan="2">
            <v-row>
              <v-col class="pa-0"
                ><small>Parallel body length [m]</small></v-col
              >
            </v-row>
            <v-row>
              <v-col class="pa-0">Aftward</v-col>
              <v-col class="pa-0">Forward</v-col>
            </v-row>
          </th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in sortedItemsOnCapacity">
          <ShipTableRow :item="item" />
        </template>
      </tbody>
    </v-table>
    <ActionMenu
      auth-source="ProjectUser"
      auth-model="ship"
      :model-value="menu"
      :position-x="shipMenuCoordinates.x"
      :position-y="shipMenuCoordinates.y"
      delete-item
      @toggle="store.toggleMenu(undefined, item)"
      @delete="store.delete(currentShip)"
    >
    </ActionMenu>
  </div>
</template>

<style scoped></style>
