<script setup>
import HelpTextTooltip from "../form/HelpTextTooltip.vue";
import Field from "@/components/form/Field.vue";
import { useCalculationStore } from "@/stores/calculation";
import { useOptimisationStore } from "@/stores/optimisation";
import { storeToRefs } from "pinia";
import { CONTINUOUS } from "@/components/calculation/berthTypes";
import RHDHVButton from "@/components/common/RHDHVButton.vue";

const store = useCalculationStore();
const optimisationStore = useOptimisationStore();

const { currentItem } = storeToRefs(store);
const { generateLoading } = storeToRefs(optimisationStore);

function customValidatorMooringPointY(value) {
  return currentItem.value.berth_type === CONTINUOUS &&
    value > currentItem.value.y_location_platform
    ? "Mooring point Y-coordinate must be smaller than platform Y-coordinate"
    : true;
}
</script>

<template>
  <v-list-item class="pb-0 pt-2">
    <template #title>
      <span> Mooring point configuration </span>
      <RHDHVButton
        class="float-right"
        :loading="generateLoading"
        item-class="ml-0"
        allowed="add_mooringpoint"
        auth-source="ProjectUser"
        variant="elevated"
        size="small"
        append-icon="mdi-plus-box-multiple"
        @click="optimisationStore.generate()"
      >
        Generate
      </RHDHVButton>
    </template>

    <v-row class="mt-0">
      <v-col cols="6" class="pb-1">
        <Field
          :store="store"
          :field="store.fields['maximum_number_of_lines']"
          :item="currentItem"
          @update="store.update()"
        ></Field>
      </v-col>
      <v-col class="pb-1">
        <Field
          :store="store"
          :field="store.fields['min_line_length']"
          :item="currentItem"
          @update="store.update()"
        ></Field>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="pb-1">
        <Field
          :store="store"
          :field="store.fields['start_mooring_point_x']"
          :item="currentItem"
          @update="store.update()"
        ></Field>
      </v-col>
      <v-col class="pb-1">
        <Field
          :store="store"
          :field="store.fields['mooring_point_spacing']"
          :item="currentItem"
          warning
          :warning-rules="[
            {
              min_value: 15,
              message: 'This is a relatively low spacing',
            },
            {
              max_value: 30,
              message: 'This is a relatively high spacing',
            },
          ]"
          @update="store.update()"
        ></Field>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="6">
        <Field
          :custom-error-rules="[customValidatorMooringPointY]"
          custom-label="Mooring point Y-coordinate [m]"
          :store="store"
          :field="store.fields['mooring_point_y']"
          :item="currentItem"
          @update="store.update()"
        ></Field>
      </v-col>
    </v-row>
  </v-list-item>
  <v-divider></v-divider>
  <v-list-item class="pb-0 pt-4" title="Quay deck size and location">
    <template #title>
      <span class="mr-3"> Berth platform size and location</span>
      <HelpTextTooltip
        :text="'Define the size and location of the berth (Y-coordinate is lateral distance of quay/deck front behind the berthing line)'"
        size="small"
      ></HelpTextTooltip>
    </template>
    <v-row class="pt-2 pb-0">
      <v-col class="pr-1">
        <Field
          v-if="store.fields['quay_level']"
          :store="store"
          :field="store.fields['quay_level']"
          :item="currentItem"
          @update="store.update()"
        ></Field>
      </v-col>
      <v-col class="pl-1 pr-1">
        <Field
          v-if="store.fields['y_location_platform']"
          :store="store"
          :field="store.fields['y_location_platform']"
          :item="currentItem"
          :step="0.5"
          @update="store.update()"
        ></Field>
      </v-col>
      <v-col class="pl-1 pr-2">
        <Field
          v-if="store.fields['platform_width']"
          style="z-index: 1"
          :store="store"
          :field="store.fields['platform_width']"
          :item="currentItem"
          :step="0.5"
          warning
          :warning-rules="[
            {
              min_value: 10,
              message: 'This is a relatively small platform width.',
            },
          ]"
          @update="store.update()"
        ></Field>
      </v-col>
      <v-col class="pl-0">
        <Field
          v-if="store.fields['platform_length']"
          style="z-index: 1"
          :store="store"
          :field="store.fields['platform_length']"
          :item="currentItem"
          :step="0.5"
          warning
          :warning-rules="[
            {
              min_value: 10,
              message: 'This is a relatively small platform width.',
            },
          ]"
          @update="store.update()"
        ></Field>
      </v-col>
    </v-row>
  </v-list-item>
</template>
