<script setup>
import DocumentationButton from "./DocumentationButton.vue";
import { storeToRefs } from "pinia";
import { useProjectStore } from "@/stores/project";
import { useCalculationStore } from "@/stores/calculation";
import { useRoute } from "vue-router/dist/vue-router";
import { computed } from "vue";

const props = defineProps({ showCalculation: Boolean });

const { currentItem: currentProject } = storeToRefs(useProjectStore());
const { currentItem: currentCalculation } = storeToRefs(useCalculationStore());

const route = useRoute();

const currentRouteName = computed(() => route.name);
</script>

<template>
  <v-toolbar density="compact" color="secondary" style="width: 100%">
    <v-app-bar-nav-icon v-if="showCalculation"> </v-app-bar-nav-icon>
    <div class="ml-4">
      <v-toolbar-title
        style="font-size: 18px; font-weight: lighter; font-family: Bahnschrift"
        >MOOR<sup>2</sup>
      </v-toolbar-title>
    </div>
    <v-divider vertical class="ml-4"></v-divider>
    <v-breadcrumbs>
      <v-breadcrumbs-item>
        <router-link
          class="text-white toolbar-text"
          :to="{ name: 'project-index' }"
        >
          Home
        </router-link>
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider />
      <v-breadcrumbs-item>
        <router-link
          class="text-white toolbar-text"
          :to="{ name: 'project-index' }"
        >
          Projects
        </router-link>
      </v-breadcrumbs-item>

      <template
        v-if="currentRouteName !== 'project-index' && currentProject.id"
      >
        <v-breadcrumbs-divider />
        <v-breadcrumbs-item>
          <router-link
            class="text-white toolbar-text"
            :to="{ name: 'calculation-index', id: currentProject.id }"
          >
            {{ currentProject.name }}
          </router-link>
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider />
        <v-breadcrumbs class="text-white toolbar-text">
          <router-link
            class="text-white toolbar-text"
            :to="{ name: 'calculation-index', id: currentProject.id }"
          >
            Calculations
          </router-link>
        </v-breadcrumbs>

        <template
          v-if="
            currentCalculation.id && currentRouteName !== 'calculation-index'
          "
        >
          <v-breadcrumbs-divider />
          <v-breadcrumbs class="text-white toolbar-text">
            {{ currentCalculation.name }}
          </v-breadcrumbs>
          <v-breadcrumbs-divider />
          <v-breadcrumbs class="text-white toolbar-text">
            Details
          </v-breadcrumbs>
        </template>
      </template>
    </v-breadcrumbs>
    <v-spacer></v-spacer>
    <div class="float-right">
      <DocumentationButton />
    </div>
  </v-toolbar>
</template>

<style scoped>
.toolbar-text {
  font-size: 15px;
  text-decoration: none;
}

.v-breadcrumbs-divider {
  opacity: 0.5;
}
</style>
