<script setup>
import { useRoute } from "vue-router/dist/vue-router";
import router from "../../router";
import ProjectTitle from "../calculation/ProjectTitle.vue";

const route = useRoute();

function navigateToUserManagement() {
  router.push({ name: "user-management", params: { id: route.params.id } });
}

function navigateToCalculationPage() {
  router.push({ name: "project-detail", params: { id: route.params.id } });
}

function navigateToProjects() {
  router.push({ name: "project-index" });
}
</script>

<template>
  <v-navigation-drawer>
    <v-list>
      <v-list-item>
        <template #title>
          <ProjectTitle />
        </template>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        prepend-icon="mdi-keyboard-return"
        @click="navigateToProjects()"
      >
        Back to projects
      </v-list-item>
      <v-list-item
        prepend-icon="mdi-calculator"
        @click="navigateToCalculationPage()"
      >
        Calculations
      </v-list-item>
      <v-list-item
        prepend-icon="mdi-account"
        @click="navigateToUserManagement()"
      >
        User Management
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
