<script setup>
import FormFiltered from "../form/FormFiltered.vue";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia/dist/pinia";

const props = defineProps({
  name: { type: String, required: false },
  store: { type: Object, required: true },
});

const { dialog, writeLoading, newItem } = storeToRefs(props.store);
</script>
<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="pt-6">Create {{ name }}</v-card-title>
      <v-card-text>
        <FormFiltered :store="store" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="writeLoading"
          color="primary"
          text
          @click="store.create(newItem)"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
