<script setup>
import { storeToRefs } from "pinia/dist/pinia";
import { useProjectStore } from "../../stores/project";

const { loading } = storeToRefs(useProjectStore());
</script>
<template>
  <div>
    <v-container fluid>
      <v-row class="text-center">
        <v-col class="pa-0" cols="12">
          <v-img
            height="30vh"
            cover
            style="background-color: #dddddd; width: auto"
            :src="'/project_header.jpeg'"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-progress-linear v-if="loading" indeterminate color="primary">
    </v-progress-linear>
  </div>
</template>
