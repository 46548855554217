<script setup>
import AddShipTable from "./AddShipTable.vue";
import AddShipSelectedShip from "./AddShipSelectedShip.vue";
import { computed } from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import ShipChart from "../chart/ShipChart.vue";

import { useShipStore } from "../../stores/ship";
import { useShipApiStore } from "../../stores/shipApi";

const shipStore = useShipStore();
const shipApiStore = useShipApiStore();

const { items, loading } = storeToRefs(useShipApiStore());
const { addLoading } = storeToRefs(useShipStore());

const emit = defineEmits(["setDialog"]);
const props = defineProps({
  dialog: { type: Boolean, required: true },
});

let dialogValue = computed({
  get() {
    return props.dialog;
  },
  set(value) {
    emit("setDialog", value);
  },
});

async function saveShip() {
  await shipStore.addShip();
  emit("setDialog", false);
  shipApiStore.removeSelectedItems();
}
</script>

<template>
  <v-dialog
    v-model="dialogValue"
    style="background-color: white"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="dialogValue = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Add vessel</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          size="large"
          variant="text"
          :loading="addLoading"
          append-icon="mdi-content-save"
          @click="saveShip()"
        >
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row no-gutters>
      <v-col class="fill-height pa-0" cols="7">
        <AddShipTable></AddShipTable>
      </v-col>
      <v-col class="border-left-grey fill-height" cols="5">
        <div class="d-flex fill-height flex-column pl-4">
          <v-lazy class="d-flex flex-column" style="height: 80vh">
            <!--            TODO still required a 80vh to let the overflow and the chart take the correct height-->
            <ShipChart v-if="items.length"></ShipChart>
            <div v-else class="d-flex align-center justify-center fill-height">
              <div class="text-center">No data available</div>
            </div>
          </v-lazy>
          <v-lazy class="d-flex flex-column overflow-y-auto">
            <AddShipSelectedShip></AddShipSelectedShip>
          </v-lazy>
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>
