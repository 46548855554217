<script setup>
import { useCalculationStore } from "@/stores/calculation";
import { useRoute } from "vue-router/dist/vue-router";
import router from "../../router";
import { computed } from "vue";

const route = useRoute();
const store = useCalculationStore();

const props = defineProps({
  order: { type: Number, required: true },
  item: { type: Object, required: true },
});

const berthType = computed(() => {
  return store.fields?.berth_type.choices.find(
    (choice) => choice.value === props.item.berth_type
  )?.display_name;
});

const shipType = computed(() => {
  return store.fields?.ship_type.choices.find(
    (choice) => choice.value === props.item.ship_type
  )?.display_name;
});

const mooringSide = computed(() => {
  return store.fields?.mooring_side.choices.find(
    (choice) => choice.value === props.item.mooring_side
  )?.display_name;
});

function navigateToCalculation(id) {
  router.push({
    name: "calculation",
    params: { id: route.params.id, calculationId: id },
  });
}

function toggleMenu(event) {
  const item = JSON.parse(JSON.stringify(props.item));
  store.toggleMenu({ x: event.clientX, y: event.clientY }, item);
}
</script>
<template>
  <tr
    v-ripple
    style="cursor: pointer"
    @contextmenu.prevent="toggleMenu"
    @click="navigateToCalculation(item.id)"
  >
    <td style="width: 5%">{{ order }}</td>
    <td style="width: 20%">
      {{ item.name }}
    </td>
    <td style="width: 10%">
      {{ berthType }}
    </td>
    <td style="width: 10%">
      {{ shipType }}
    </td>
    <td style="width: 15%">
      {{ mooringSide }}
    </td>
    <td style="width: 50%">
      {{ item.description }}
    </td>
  </tr>
</template>
