<script setup>
import { useShipStore } from "@/stores/ship";
import { ref, watch, computed } from "vue";
import FairleadTable from "../fairlead/FairleadTable.vue";
import Field from "../form/Field.vue";
import ShipActionMenu from "./ShipRowActionMenu.vue";
import { useAuthorizationStore } from "@/stores/authorization";

const store = useShipStore();
const authorizationStore = useAuthorizationStore();

const props = defineProps({
  item: { type: Object, required: true },
});

const open = ref(false);

function setCurrentShip() {
  if (props.item.hide) {
    return store.setHide(props.item);
  }
  if (open.value) {
    store.setCurrentItem({});
  } else {
    store.setCurrentItem(props.item);
  }
  open.value = !open.value;
  store.setShowLines(props.item, open.value);
}

const field = computed(() => {
  let eccentricity = { ...store.fields["eccentricity"] };
  eccentricity["min_value"] = - props.item.length_overall / 2;
  eccentricity["max_value"] = props.item.length_overall / 2;
  return eccentricity;
});

const canEdit = computed(() =>
  authorizationStore.hasServicePermission({
    authSource: "ProjectUser",
    allowed: "change_calculation",
  })
);

watch(
  () => props.item.eccentricity,
  async () => {
    store.update(props.item);
  }
);
</script>

<template>
  <tr
    v-ripple
    :class="item.hide ? 'opacity clickable' : 'clickable'"
    @click.stop="setCurrentShip()"
    @contextmenu.prevent="
      store.toggleMenu({ x: $event.clientX, y: $event.clientY }, item)
    "
  >
    <td style="width: 1%" class="pr-0">
      <v-btn
        size="small"
        :icon="open ? `mdi-arrow-up-drop-circle` : `mdi-arrow-down-drop-circle`"
        variant="flat"
        @click.stop="setCurrentShip()"
      ></v-btn>
    </td>
    <td>{{ item.capacity }}</td>
    <td class="center">
      <Field
        :disabled="!canEdit"
        :store="store"
        :field="field"
        :item="item"
        density="compact"
        variant="plain"
        hide-details
        no-label
        no-update-on-error
        no-padding
        center
        font-size="14px"
      ></Field>
    </td>
    <td>{{ item.length_overall }}</td>
    <td>{{ item.breadth }}</td>
    <td>{{ item.laden_draught }}</td>
    <td>{{ item.ballasted_draught }}</td>
    <td>{{ item.depth_main_deck_to_keel }}</td>
    <td>{{ item.forward_parallel_body_length }}</td>
    <td>{{ item.aftward_parallel_body_length }}</td>
    <td style="width: 1%">
      <ShipActionMenu :item="item"></ShipActionMenu>
    </td>
  </tr>
  <tr v-show="open">
    <td colspan="16" class="pa-0">
      <FairleadTable :ship="item" />
    </td>
  </tr>
</template>

<style scoped>
td {
  text-align: center;
}

.opacity {
  opacity: 0.5;
}

tr:hover {
  background-color: #f5f5f5;
}
</style>
