<script setup>
import { computed, ref, watch } from "vue";
import HelpTextTooltip from "./HelpTextTooltip.vue";

const props = defineProps({
  item: { type: Object, required: true },
  field: { type: Object, required: true },
  store: { type: Object, required: true },
  hideDetails: { type: Boolean, required: false, default: false },
  center: { type: Boolean, required: false, default: false },
  noPadding: { type: Boolean, required: false, default: false },
  noLabel: { type: Boolean, required: false, default: false },
  noHelpText: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  density: { type: String, required: false, default: "default" },
  variant: { type: String, required: false, default: "filled" },
});
const emit = defineEmits(["update"]);

const typeMapping = ref({
  float: "number",
  integer: "number",
  string: "text",
});
const rules = ref({ required: (value) => !!value || "Required." });

watch(props.store, () => {});

const value = computed({
  get() {
    let value = props.item[props.field.parameter];
    if (typeof value === "number") {
      value = value.toFixed(2);
    }
    return value;
  },
  set(value) {
    props.store.setParameter(props.item, props.field.parameter, value);
    emit("update");
  },
});

const noPaddingClass = computed({
  get() {
    return props.noPadding ? "no-padding no-padding-append-inner" : "";
  },
});

const fieldInputCenterClass = computed({
  get() {
    return props.center ? "field-input-center" : "";
  },
});

const type = computed({
  get() {
    return props.item[props.field.parameter];
  },
});
</script>

<template>
  <div v-if="field" @click.stop>
    <v-select
      v-if="field.type === 'choice'"
      v-model="value"
      :class="`${noPaddingClass} ${fieldInputCenterClass}`"
      :density="density"
      :variant="variant"
      :label="!noLabel ? field.label : null"
      :disabled="disabled"
      color="primary"
      :items="field.choices"
      item-value="value"
      item-title="display_name"
      :hide-details="hideDetails"
    >
      <template #append-inner>
        <div class="pt-0">
          <HelpTextTooltip
            v-if="field.help_text && !noHelpText"
            :text="field.help_text"
          />
        </div>
      </template>
    </v-select>
  </div>
</template>

<style scoped>
.no-padding :deep(.v-field__input) {
  padding-top: 4px;
}

.no-padding-append-inner :deep(.v-field__append-inner) {
  padding-top: 4px;
}

.field-input-center :deep(.v-field__input) {
  text-align: center;
}
.field-input-center-append-inner :deep(.v-field__input) {
  text-align: center;
}
</style>
