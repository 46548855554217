<script setup>
import { useProjectUserStore } from "@/stores/project_user";

const store = useProjectUserStore();
import RHDHVButton from "@/components/common/RHDHVButton.vue";
</script>

<template>
  <v-card elevation="0">
    <v-card-title>
      User management
      <RHDHVButton
        allowed="add_projectuser"
        auth-source="ProjectUser"
        @click="store.toggleAddUserDialog()"
      >
        Add
      </RHDHVButton>
    </v-card-title>
    <v-card-subtitle>
      Please configure the permissions for users within this project, add a new
      user via the
      <v-chip
        class="ml-1 mr-1"
        append-icon="mdi-plus"
        label
        variant="outlined"
        size="x-small"
        color="primary"
      >
        ADD
      </v-chip>
      button.
    </v-card-subtitle>
  </v-card>
</template>
