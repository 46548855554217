<script setup>
import router from "../../router";
import { storeToRefs } from "pinia/dist/pinia";
import { useDjangoAuthStore } from "@/stores/authDjango";

const store = useDjangoAuthStore();
const userStore = useDjangoAuthStore();

const { user: currentUser } = storeToRefs(userStore);

async function logOut() {
  await store.logout();
  router.push({ name: "auth-login" });
}
</script>

<template>
  <v-menu class="center" :offset-x="true">
    <template #activator="{ props }">
      <v-btn icon color="primary" v-bind="props" @mousedown.stop>
        <v-icon @mousedown.stop>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item dense>
        <v-list-item-title>
          <div class="elip">
            <v-icon class="mr-2"> mdi-account</v-icon>
            {{ currentUser.username }}
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-list-item dense @click="logOut()">
        <v-list-item-title>
          <div class="elip">
            <v-icon class="mr-2"> mdi-logout-variant</v-icon>
            Log out
          </div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>
:deep(.v-toolbar__extension) {
  padding: 0 !important;
}
</style>
