<script setup>
import { storeToRefs } from "pinia/dist/pinia";
import { useProjectStore } from "../../stores/project";
import RHDHVButton from "@/components/common/RHDHVButton.vue";

const { loading } = storeToRefs(useProjectStore());

const store = useProjectStore();
</script>

<template>
  <v-card v-if="!loading" elevation="0">
    <v-card-title>
      Projects

      <RHDHVButton
        allowed="add_project"
        auth-source="User"
        @click="
          store.setCurrentItem({});
          store.toggleCreateUpdateDialog();
        "
      >
        Add
      </RHDHVButton>
    </v-card-title>
    <v-card-subtitle>
      To start using <span class="moor-font">MOOR²</span>, please select an
      existing project below or create a new project via the
      <v-chip
        class="ml-1 mr-1"
        append-icon="mdi-plus"
        label
        variant="outlined"
        size="x-small"
        color="primary"
      >
        ADD
      </v-chip>
      button.
    </v-card-subtitle>
  </v-card>
</template>
