<script setup>
import ToolBar from "./ToolBar.vue";
import Loader from "./Loader.vue";
import HeaderMenu from "./HeaderMenu.vue";
import HeaderLogo from "./HeaderLogo.vue";
import { useAuthorizationStore } from "@/stores/authorization";
import { storeToRefs } from "pinia";
import { inject, onMounted } from "vue";

const authorizationStore = useAuthorizationStore();

const { items: authorizations } = storeToRefs(authorizationStore);

const authStore = inject("useAuthStore");

const { user } = storeToRefs(authStore);

onMounted(async () => {
  if (authorizations.value.length === 0) {
    await authorizationStore.fetchItems();
  }
  authorizationStore.setCurrentItem([user.value.role]);
});
</script>

<template>
  <v-app-bar
    fixed
    app
    color="white"
    dark
    class="pa-0"
    height="90"
    extension-height="40"
  >
    <HeaderLogo />
    <v-spacer></v-spacer>
    <Loader></Loader>
    <HeaderMenu />
    <template #extension>
      <ToolBar />
    </template>
  </v-app-bar>
</template>

<style scoped>
:deep(.v-toolbar__extension) {
  padding: 0 !important;
}

.v-toolbar__extension {
  padding: 0 !important;
}
</style>
